/*=========================================================================================
  File Name: index.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import {alert} from "./alert.module";
import {authentication} from "./auth.module";
import {sidebar} from "../../shared/store/sidebar.module"

Vue.use(Vuex);


export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
		sidebar,
    },
    strict: process.env.NODE_ENV !== 'production'
})

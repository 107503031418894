import config from "./../../config"
import axios from 'axios'
import {localstorage} from "../../_helpers"

const apiUrl = config.API_URL


export const loginService = {
    login,
    logout,
	forgotPassword,
	resetPassword,
	getPreferences,
	updatePreferences,
}

async function getPreferences() {
	let response = await axios.get('/account/preferences')
    return response ? response.data : null
}

async function updatePreferences(preferences) {
    let response = await axios.put(
        '/account/preferences',
		preferences,
		{
				headers: {
					'Authorization': "Bearer " + token
				}
		}
	)
    return response ? response.data : null
}

function forgotPassword(username) {
	const requestOptions = {
		method: "POST",
		headers: {"Content-Type": "application/json"},
		body: JSON.stringify(username)
	}

	return fetch(apiUrl + 'account/forgot-password', requestOptions)
}

function resetPassword(data) {
	const requestOptions = {
		method: "POST",
		headers: {"Content-Type": "application/json"},
		body: JSON.stringify(data)
	}

	return fetch(apiUrl + 'account/reset-password', requestOptions)
}

function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({username, password})
    }

    return fetch(apiUrl + "login", requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token) {
                let storage_user = {
                    ...user
                }
                //store the token in localStorage
                localStorage.setItem(config.AUTH_USER_KEY, JSON.stringify(storage_user));
				localStorage.removeItem(config.IMPERSONATE_USER_KEY);
			}
            if ('cf_cookies' in user && user.cf_cookies.length > 0){
                user.cf_cookies.forEach((cookie) => {
                    let expiresTS  = cookie.expires;
                    let name = cookie.name;
                    let value = cookie.value;
                    let expires = "; expires=" + (new Date(expiresTS * 1000)).toUTCString();
                    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
                });
            }

            return user
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(config.AUTH_USER_KEY);
	localStorage.removeItem(config.IMPERSONATE_USER_KEY);
}

async function handleResponse(response) {
    let text = await response.text()
    const data = text && JSON.parse(text)
    if (!response.ok) {
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }
    return data
}

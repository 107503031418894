/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - Clean Vue.js Dashboard Admin Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/



import Vue from 'vue'
import Vuesax from 'vuesax'
import config from "./src/config"

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
    primary: '#236192',
    info: '#41B6EB',
    warning: '#FF9425',
    secondary: '#017BB0',
    danger: '#C02126',
    success: '#128919',
    darkblue: '#252A36',
    dark: '#1E1E1E',
    // primary: '#7367F0',
    // danger: '#ff0000',
    // warning: '#FF9F43',
    ...config.COLORS
}

Vue.use(Vuesax, { theme:{ colors } });


// CONFIGS
const themeConfig = {
    theme: 'light',						// options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false,			// options[Boolean]: true, false(default)
    navbarColor: "#fff",				// options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
    navbarType: "floating",				// options[String]: floating(default) / static / sticky / hidden
    footerType: "static",				// options[String]: static(default) / sticky / hidden
    routerTransition: 'zoom-fade',		// options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
    disableCustomizer: false,			// options[Boolean]: true, false(default)
    hideScrollToTop: false,				// options[Boolean]: true, false(default)
    disableThemeTour: false,					// options[Boolean]: true, false(default)
    vuesax_colors: {...colors}
  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}

export default themeConfig

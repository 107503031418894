import i18n from '../shared/i18n/i18n'

const dict = {
	custom: {
		common: {
			required: i18n.t('required_field!'),
			alpha: i18n.t('this_field_may_only_contain_alphabetic_characters')
		},
		first_name: {
			required: i18n.t('the_first_name_field_is_required'),
			alpha: i18n.t('first_name_may_only_contain_alphabetic_characters')
		},
		last_name: {
			required: i18n.t('the_last_name_field_is_required'),
			alpha: i18n.t('last_name_may_only_contain_alphabetic_characters')
		},
		email: {
			required: i18n.t('the_email_field_is_required'),
			email: i18n.t('please_enter_valid_email')
		},
		password: {
			required: i18n.t('the_password_field_is_required'),
		},
		password_confirmation: {
			required: i18n.t('the_password_confirmation_field_is_required'),
		},
		address_name: {
			required: i18n.t('the_address_name_field_is_required')
		},
		zip_code: {
			required: i18n.t('the_zip_postal_code_field_is_required')
		},

	}
};

export const validationHelper = {
	dict,
};



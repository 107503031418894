// en, fr
export default {
    en: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        you_are_about_to_delete_media: 'You are about to delete the media!',
        the_address_name_field_is_required: 'The address name field is required',
        required_field: 'Required field!',
        reset_password: 'Reset password',
        create_new_password: 'Please create your new password.',
        reset: 'Reset',
        password_changed: 'Your password was changed successfully!',
        username_is_required: 'Username is required.',
        logging_in: 'Logging In...',
        remember_me: 'Remember Me',
        forgot_password: 'Forgot Password?',
        welcome: 'Welcome back, please login to your account.',
        login: 'Login',
        provide_username: 'Please provide your username to recover the password.',
        back_to_login: 'Back to login',
        send: 'Send',
        email_was_sent: 'Email was sent',
        we_sent_email: 'We sent an email message with the instructions to the associated email address.',
        please_check_your_email: 'Please check your email and follow the instructions to recover your password.',
        past_due: 'Past due',
        OldestFirst: 'Oldest First',
        OldestLast: 'Oldest Last',
        DevicesLastTested: 'Devices Last Tested',
        DevicesByDueDate: 'Devices by Due Date',
        Chill_Water: 'Chill Water',
        Process_Water: 'Process Water',
        Domestic: 'Domestic',
        Fire_Protection: 'Fire Protection',
        Fire_Bypass: 'Fire Bypass',
        Irrigation: 'Irrigation',
        Backflow_test_price: 'Backflow test price',
        Describes_the_cost_of_a_backflow_test_: 'Describes the cost of a backflow test',
        Non_compliance_Date_Range: 'Non-compliance Date Range',
        The_number_of_days_past_a_device_s_due_date_before_the_device_becomes_non_compliant: 'The number of days past a devices due date before the device becomes non-compliant',
        generate_letters: 'Generate letters',
        switch_to_bsi_20: 'Switch to BSI 2.0',
        All_documents_should_be_placed_here: 'All documents should be placed here',
        Irrigation_shut_off_forms: 'Irrigation shut off forms',
        All_the_other_stuff_goes_here: 'All the other stuff goes here',
        Pictures_folder: 'Pictures folder',
        Property_assets: 'Property assets',
        this_week: 'This week',
        last_7_days: 'Last 7 days',
        this_month: 'This month',
        this_quarter: 'This quarter',
        last_30_days: 'Last 30 days',
        last_month: 'Last month',
        last_quarter: 'Last Quarter',
        last_12_months: 'Last 12 months',
        this_year: 'This year',
        present_to_year_end: 'Now to end of year',
        last_year: 'Last year',
        custom: 'Custom',
        day: 'day',
        week: 'week',
        month: 'month',
        Month: 'Month',
        quarter: 'quarter',
        EditProperty: 'Edit Property',
        ForecastReport: 'Forecast Report',
        GrowthReport: 'Growth Report',
        TestedReport: 'Tested Report',
        PropertyDetails: 'Property Details',
        DeviceDetails: 'Device Details',
        TestingCompanies: 'Testing Companies',
        TestingCompanyDetails: 'Testing Company Details',
        NonCompliant: 'Non-Compliant',
        DEP: 'DEP',
        DPH: 'DPH',
        EPA: 'EPA',
        AddUser: 'Add User',
        MoveDevice: 'Move Device',
        EditUser: 'Edit User',
        edit_user: 'Edit User',
        Profile: 'Profile',
        UserActivity: 'User Activity',
        OrganizationStructure: 'Organization Structure',
        OrganizationDetails: 'Organization Details',
        OrganizationSettings: 'Organization Settings',
        AssetCategories: 'Asset Categories',
        AddProperty: 'Add Property',
        forecast: 'Forecast',
        number_of_devices_due: 'Number of devices due',
        number_of_devices_by_due_date: 'Number of devices by due date',
        growth: 'Growth',
        view_details: 'View details',
        number_of_active_and_inactive_devices: 'Number of active and inactive devices',
        devices_last_tested: 'Devices last tested',
        number_of_devices_that_have_most_recent_test_performed_in_the_selected_period: 'Number of devices that have most recent test performed in the selected period',
        sorry_but_you_have_no_permission_to_see_this_page: 'Sorry, but you have no permission to see this page',
        please_contact_your_administrator: 'Please contact your administrator',
        update_profile: 'Update Profile',
        username: 'Username',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        cancel: 'Cancel',
        update: 'Update',
        change_password: 'Change Password',
        current_password: 'Current Password',
        password: 'Password',
        confirm_password: 'Confirm Password',
        profile_settings: 'Profile Settings',
        upload_picture: 'Upload Picture',
        full_name: 'Full Name',
        notifications: 'Notifications',
        no_notifications: 'No notifications',
        help_desk: 'Helpdesk',
        "Helpdesk": 'Helpdesk',
        HelpDesk: 'Helpdesk',
        search_for_properties: 'Search for properties',
        search_by_address_name_or_ccn: 'Search by address, name or CCN',
        add_property: 'Add property',
        start_typing_here: 'Start typing here ...',
        account: 'Account',
        name: 'Name',
        ccn: 'CCN',
        physical_address: 'Physical Address',
        mailing_address: 'Mailing Address',
        move_here: 'Move here',
        sorry_but_no_results_were_found: 'Sorry, but no results were found',
        searching: 'Searching...',
        add_role: 'Add Role',
        search_testers: "Search for testers",
        search_users: 'Search for users',
        assign_user_to_the_role: 'Assign user to the role',
        assign: 'Assign',
        edit_role: 'Edit Role',
        delete_role: 'Delete Role',
        add_user: 'Add user',
        users: 'Users',
        revoke: 'Revoke',
        permissions: 'Permissions',
        drag_and_drop: 'Drag & Drop',
        users_with_the_role: 'Users with the role',
        users_without_the_role: 'Users without the role',
        role_name: 'Role name',
        role_description: 'Role description',
        search_for_testing_companies: 'Search for testing companies',
        testing_companies: 'Testing Companies',
        "Testing Companies": 'Testing Companies',
        export_as_csv: 'Export as CSV',
        contact: 'Contact',
        statistics: 'Statistics',
        properties_tested: 'Properties tested',
        devices_tested: 'Devices tested',
        total_tests: 'Total tests',
        activity_log: 'Activity log',
        activity: 'Activity',
        search_records: 'Search records',
        all: 'All',
        success: 'Success',
        fail: 'Fail',
        assign_new_role: 'Assign new role',
        roles: 'Roles',
        current_to_date: 'Current to date',
        active: 'Active',
        Active: 'Active',
        inactive: 'Inactive',
        users_hierarchy: 'Users hierarchy',
        blocked: 'Blocked',
        receiving_reports: 'receiving reports',
        edit: 'Edit',
        last_login_at: 'Last login at',
        n_a: 'n/a',
        last_login_from: 'Last login from',
        user_roles: 'User roles',
        are_you_sure: 'Are you sure?',
        yes: 'Yes',
        YES: 'YES',
        no: 'No',
        NO: 'NO',
        not_authorized: 'Not Authorized',
        Deactivate: 'Deactivate',
        Activate: 'Activate',
        device_deactivate: 'Device was deactivated successfully',
        device_activate: 'Device was activated successfully',
        property_deactivate: 'Property was deactivated successfully',
        property_activate: 'Property was activated successfully ',
        no_data: 'no data',
        export_testers: 'Export testers',
        export_tests: 'Export tests',
        export_licenses: 'Export licenses',
        tests_performed: 'Tests performed',
        type: 'Type',
        tests: 'Tests',
        date: 'Date',
        tester: 'Tester',
        device: 'Device',
        hazard: 'Hazard',
        sn: 'SN',
        mn: 'MN',
        result: 'Result',
        serial_number: 'Serial number',
        meter_number: 'Meter number',
        test_result: 'Test result',
        testing_licenses: 'Testing licenses',
        number: 'Number',
        expiration_date: 'Expiration date',
        expiration: "Expiration",
        vetted: 'Vetted',
        license_number: 'License number',
        tester_company: 'Tester company',
        no_results: 'No results',
        manufacturer: 'Manufacturer',
        Manufacturer: 'Manufacturer',
        replace: 'Replace',
        add: 'Add',
        save: 'Save',
        due_date: 'Due Date',
        change: 'Change',
        edit_property_details: 'Edit property details',
        edit_contact_details: 'Edit contact details',
        edit_mailing_address: 'Edit mailing address',
        edit_physical_address: 'Edit physical address',
        change_contact: 'Change contact',
        change_mailing_address: 'Change mailing address',
        change_physical_address: 'Change physical address',
        compliance: 'Compliance',
        pwsid: 'PWSID',
        rsg: 'RSG',
        replace_device: 'Replace device',
        current_device: 'Current Device',
        location: 'Location',
        hazard_type: 'Hazard Type',
        model: 'Model',
        size: 'Size',
        potable: 'Potable',
        existing: 'Existing',
        new_device: 'New Device',
        new_installs_and_replacements: 'New Installs and Replacements',
        initial_due_date: 'Initial Due Date',
        change_due_date: 'Change Due Date',
        grant_extension_date: 'Grant extension date',
        new_date: 'New date',
        mandatory: 'mandatory',
        quick_select: 'Quick select',
        weeks: 'weeks',
        reason: 'Reason',
        grant: 'Grant',
        move: 'Move',
        grant_extension: 'Grant extension',
        device_serial_number: 'Device serial number',
        device_meter_number: 'Device meter number',
        additional_info: 'Additional info',
        isolation_containment: 'Isolation/Containment',
        premise: 'Premise',
        last_test_info: 'Last test info',
        by: 'by',
        general: 'General',
        timeline: 'Timeline',
        move_device: 'Move device',
        please_confirm_you_want_to_move_the_selected_device: 'Please confirm you want to move the selected device',
        from_the_current_property: 'From the current property',
        to_this_property: 'To this property',
        selected_device: 'Selected device',
        search_for_a_property_to_move_the_device_to: 'Search for a property to move the device to',
        choose_the_property: 'Choose the property',
        contact_details: 'Contact Details',
        phone: 'Phone',
        address: 'Address',
        address_two: 'Address 2',
        city: 'City',
        City: 'City',
        state: 'State',
        province: 'Province',
        zip: 'ZIP',
        postal_code: 'Postal Code',
        organization_structure: 'Organization Structure',
        property_details: 'Property Details',
        residential: 'Residential',
        property_name: 'Property Name',
        account_number: 'Account number',
        delete: 'Delete',
        add_new_property: 'Add new property',
        org_structure: 'Org structure',
        address_name: 'Address name',
        same_as_physical: 'Same as physical',
        property_information: 'Property information',
        is_this_a_residence: 'Is this a residence',
        contact_information: 'Contact Information',
        fax: 'Fax',
        details: 'Details',
        contact_info: 'Contact Info',
        comments: 'Comments',
        add_comment: 'Add comment',
        post_comment: 'Post comment',
        notify_bsi_via_email_of_your_comment: 'Notify BSI via email of your comment',
        comment: 'Comment',
        post: 'Post',
        devices: 'Devices',
        add_device: 'Add Device',
        device_timeline: 'Device Timeline',
        view_timeline: 'View Timeline',
        extension_date: 'Extension date',
        category_name: 'Category name',
        category_description: 'Category description',
        category_add: 'Category add',
        category_delete: 'Delete category',
        category_update: 'Update category',
        edit_media: 'Edit media',
        media: 'Media',
        category: 'Category',
        description: 'Description',
        upload_media: 'Upload media',
        media_is_required: 'Media is required',
        default: 'Default',
        add_category: 'Add category',
        organization_information: 'Organization Information',
        contract_expiration_date: 'Contract Expiration Date',
        generate_letter: 'Generate Letters',
        measure_system: 'Measure System',
        receive_non_compliant_emails: 'Receive Non Compliant eMails',
        special_license: 'Special License',
        test_notifications: 'Test Notifications',
        settings: 'Settings',
        organization_settings: 'Organization Settings',
        node_name: 'Node name',
        organization_hierarchy: 'Organization Hierarchy',
        search: 'Search',
        coming_soon: 'Coming soon',
        custom_reports: 'Custom Reports',
        use_this_to_generate_your_own_reports_and_save_the_criteria_to_use_again_later: 'Use this to generate your own reports and save the criteria to use again later',
        search_by_property: 'Search by property',
        search_by_device: 'Search by device',
        from: 'from',
        to: 'to',
        tested_date: 'Tested date',
        creation_date: 'Creation date',
        saved_reports: 'Saved Reports',
        type_the_name_of_the_report_here: 'Type the name of the report here',
        save_current_report: 'Save current report',
        clear_search_from: 'Clear search form',
        records_to_show: 'Records to show',
        properties: 'Properties',
        zip_code: 'Zip Code',
        last_test_result: 'Last Test Result',
        serial: 'Serial',
        install_date: 'Install Date',
        last_tested: 'Last Tested',
        tested_by: 'Tested By',
        dep: 'DEP',
        department_of_environmental_protection_report: 'Department of Environmental Protection report',
        non_residential: 'Non-residential',
        standard_service_connections: 'Standard service connections',
        dedicated_irrigation_service_connections: 'Dedicated irrigation service connections',
        dedicated_fire_service_connections: 'Dedicated fire service connections',
        total_non_residential_service_connections: 'Total non-residential service connections',
        total_residential_service_connections: 'Total residential service connections',
        tested: 'Tested',
        total: 'Total',
        service_connections: 'Service connections',
        dph: 'DPH',
        department_of_public_health_report: 'Department of Public Health Annual Report - ',
        assemblies: 'Assemblies',
        new_installs: 'New Installs',
        failed: 'Failed',
        repaired: 'Repaired',
        replaced: 'Replaced',
        environmental_protection_agency_report_as_of: 'Environmental Protection Agency report (as of',
        current_snapshot_report_as_of: 'Current Snapshot report (as of',
        total_number_of_backflow_device_records_in_database: 'Total number of backflow device records in database',
        total_number_of_backflow_prevention_devices_scheduled_for_annual_testing: 'Total number of backflow prevention devices scheduled for annual testing',
        number_of_testing_notification_forms_sent_for_the_month_of_: 'Number of testing notification forms sent for the month of ',
        number_of_valid_test_forms_received_from: 'Number of valid test forms received from ',
        _notifications: 'notifications',
        percentage_of: 'Percentage of',
        notifications_tested: 'notifications tested',
        total_number_of_devices_ordered_to_be_tested_since_january: 'Total number of devices ordered to be tested since January',
        total_number_of_devices_tested_since_january: 'Total number of devices tested since January',
        percentage_of_devices_ordered_to_be_tested_and_actually_tested_since_january: 'Percentage of devices ordered to be tested and actually tested since January',
        total_number_of_notifications_awaiting_return_of_test_forms: 'Total number of notifications awaiting return of test forms',
        percentage_of_ordered_tests_now_in_violation_since: 'Percentage of ordered tests now in violation since',
        date_range:'Date range',
        date_range_grouping: 'Date range grouping',
        data: 'Data',
        _properties: 'properties',
        forecast_details: 'Forecast details',
        number_of_devices_added_to_system: 'Number of devices added to system',
        growth_details: 'Growth details',
        sent_letters_report: 'Sent Letters Report',
        need_to_search_for_letters_sent_to_your_properties: 'Need to search for letters sent to your properties',
        property: 'Property',
        commercial: 'Commercial',
        clear: 'Clear',
        letter: 'Letter',
        template: 'Template',
        from_date: 'From Date',
        to_date: 'To Date',
        of: 'of',
        non_compliance_report: 'Non Compliance Report',
        devices_due: 'Devices due',
        properties_due: 'Properties due',
        search_by_keyword:'Search by keyword',
        order_by: 'Order By',
        days_since_compliant: 'Days Since Compliant',
        devices_las_tested: 'Devices last tested',
        tested_devices_details: 'Tested Devices details',
        testing_companies_report: 'Testing Companies Report',
        these_are_the_testing_companies_that_have_done_work_in_your_organization: 'These are the testing companies that have done work in your organization',
        testing_company: 'Testing Company',
        company_name: 'Company Name',
        tester_name: 'Tester Name',
        transactional_report: 'Transactional Report',
        this_report_shows_you_all_of_the_transactions_in_the_system: 'This report shows you all of the transactions in the system',
        active_only: 'Active only',
        search_or_start_a_new_chat: 'Search or start a new chat',
        password_confirmation: 'Password confirmation',
        letters: 'Letters',
        letters_page: 'Letters page',
        some_context_here: 'Some context here',
        past_due_devices: 'Past due devices',
        proportion_of_devices_that_are_current_to_date_and_past_due_date: 'Proportion of devices that are current to date and past due date',
        passed: 'Passed',
        no_data_about_test_results: 'no data about test results',
        no_data_about_testing_company: 'no data about testing company',
        device_was_created: 'Device was created',
        due_date_was_set_to: 'Due date was set to',
        test_was_done: 'Test was done',
        a_test_was_done_on: 'A test was done on ',
        test_result_is: 'Test result is',
        the_test_was_paid: 'The test was paid',
        extension_was_granted_set_to: 'Extension was granted. Set to',
        due_date_is_incorrect: 'Due date is incorrect',
        extension_date_is_incorrect: 'Extension date is incorrect',
        mm_dd_yyyy: 'mm/dd/yyyy',
        imperial: 'imperial',
        _name: 'name',
        results: 'Results',
        properties_and: 'properties and',
        percentage_of_total_non_compliant_devices: 'Percentage of Total non-compliant devices',
        and_percentage_of_residential_non_compliant_devices: 'and percentage of Residential non-compliant devices',
        and_percentage_of_commercial_non_compliant_devices: 'and percentage of Commercial non-compliant devices',
        this_percentage_is_acquired_by_dividing_non_compliant_devices_by_the_total_number_of_devices: 'This percentage is acquired by dividing non-compliant devices by the total number of devices',
        in_this_case: 'In this case',
        property_type: 'Property Type',
        devices_with_no_previous_data: 'Devices with no previous data',
        show: 'Show',
        hide: 'Hide',
        please_correct_the_form_errors: 'Please correct the form errors',
        edit_category: 'Edit category',
        you_are_about_to_remove_node_from_organization_tree: 'You are about to remove node from organization tree',
        yes_remove_it: 'Yes, remove it',
        you_are_about_to_deactivate_user: 'You are about to deactivate user!',
        yes_deactivate: 'Yes, deactivate!',
        permission_added: 'Permission added',
        new_permission_was_successfully_added: 'New permission was successfully added',
        permission_revoked: 'Permission revoked',
        permission_was_successfully_revoked: 'Permission was successfully revoked',
        role_was_removed: 'Role was removed',
        the_role_was_revoked_from_the_user: 'The role was revoked from the user',
        role_was_added: 'Role was added',
        the_role_was_added_to_the_user: 'The role was added to the user',
        role_added: 'Role added',
        a_new_role_was_added: 'A new role was added',
        role_updated: 'Role updated',
        the_role_was_added: 'The role was added',
        role_deleted: 'Role deleted',
        the_role_was_deleted: 'The role was deleted',
        Org_User: 'Org User',
        Organization_Administrator: 'Organization Administrator',
        Organization_User: 'Organization User',
        Org_Temp: 'Org Temp',
        Organization_Temporary: 'Organization Temprorary',
        error: 'Error',
        add_organization_node: 'Add organization node',
        edit_organization_node: 'Edit organization node',
        license_is_past_due: 'License is past due',
        license_is_due_in_less_than: 'License is due in less than ',
        days: 'days',
        license_is_valid: 'License is valid',
        add_new_device: 'Add new device',
        edit_device: 'Edit device',
        extension_was_granted_successfully: 'Extension was granted successfully',
        device_move: 'Move device',
        device_was_moved_successfully: 'Device was moved successfully',
        device_replace: 'Replace device',
        device_was_replaced_successfully: 'Device was replaced successfully',
        due_date_change: 'Change due date',
        device_due_date_was_changed_successfully: 'Device due date was changed successfully',
        device_status: 'Device status',
        device_was_successfully: 'Device was successfully ',
        New_device: 'New device',
        new_device_was_added_successfully: 'New device was added successfully',
        device_update: 'Update device',
        device_was_updated_successfully: 'Device was updated successfully',
        click_edit_to_add_description: 'Click "Edit" to add description',
        you_are_about_to_deactivate_this_property_and_all_the_devices_associated_with_it: 'You are about to deactivate this property and all the devices associated with it!',
        yes_deactivate_it: 'Yes, deactivate it!',
        correct_all_values: 'correct all values',
        yes_delete_it: 'Yes, delete it!',
        this_field_may_only_contain_alphabetic_characters: 'This field may only contain alphabetic characters',
        the_first_name_field_is_required: 'The first name field is required',
        first_name_may_only_contain_alphabetic_characters: 'First name may only contain alphabetic characters',
        the_last_name_field_is_required: 'The last name field is required',
        last_name_may_only_contain_alphabetic_characters: 'Last name may only contain alphabetic characters',
        the_email_field_is_required: 'The email field is required',
        please_enter_valid_email: 'Please enter valid email',
        the_password_field_is_required: 'The password field is required',
        the_password_confirmation_field_is_required: 'The password confirmation field is required',
        the_zip_postal_code_field_is_required: 'The zip/postal code field is required',
        Commercial: 'Commercial',
        CurrentSnapshot: 'Current Snapshot',
        Custom: 'Custom',
        dashboard: 'Dashboard',
        Dashboard: 'Dashboard',
        Details: 'Details',
        Documents: 'Documents',
        exit: 'Exit',
        Failed: 'Failed',
        Forecast: 'Forecast',
        Growth: 'Growth',
        Hazard: 'Hazard',
        impersonating: 'Impersonating',
        Inactive: 'Inactive',
        Logout: 'Logout',
        Model: 'Model',
        now_to_end_of_year: ' Now to end of year',
        Org_Admin: 'Org Admin',
        Organization: 'Organization',
        Other: 'Other',
        Pass: 'Pass',
        Pictures: 'Pictures',
        Property: 'Property',
        Reports: 'Reports',
        Residential: ' Residential',
        Roles: 'Roles',
        Size: 'Size',
        Status: 'Status',
        Structure: 'Structure',
        Users: 'Users',
        you_are_about_to_revoke_the_role: 'You are about to revoke the role!',
        yes_revoke_it: 'Yes, revoke it!',
        you_are_about_to_delete_the_role: 'You are about to delete the role!',
        media_upload: 'Upload media',
        the_media_was_successfully_added_to_the_property: 'The media was successfully added to the property',
        media_update: 'Update media',
        the_media_was_updated_successfully: 'The media was updated successfully',
        media_delete: 'Delete media',
        the_media_was_successfully_deleted_from_the_property: 'The media was successfully deleted from the property',
        possible_duplicate_physical_address_was_found: 'Possible duplicate physical address was found! Please see below.',
        property_status: 'Property status',
        property_address_update: 'Property address update',
        property_mailing_address_was_updated_successfully: 'Property mailing address was updated successfully',
        property_physical_address_was_updated_successfully: 'Property physical address was updated successfully',
        property_contact_update: 'Property contact update',
        property_contact_details_were_updated_successfully: 'Property contact details were updated successfully',
        property_update: 'Update property',
        property_was_updated_successfully: 'Property was updated successfully',
        new_property_added: 'New property added',
        new_property_was_added_successfully: 'New property was added successfully',
        new_comment: 'New comment',
        comment_was_successfully_added: 'Comment was successfully added',
        sorry_no_matching_options: 'Sorry, no matching options',
        _devices: 'devices',
        new_devices: 'New Devices',
        you_are_about_to_deactivate_this_device: 'You are about to deactivate this device!',
        you_are_about_to_delete_the_media: 'You are about to delete the media!',
        reset_preferences: 'Reset preferences',
        language: 'Language',
        english: 'English',
        preference_settings: 'Preference Settings',
        tour: 'Tour',
        reset_tour: 'Reset Tour',
        finished: 'Finished',
        in_progress: 'In progress',
        you_are_about_to_reset_tour: 'You are about to reset tour',
        yes_reset_it: 'Yes, reset it!',
        take_a_tour: 'Take a tour',
        you_are_about_to_delete_this_category: 'You are about to delete this category',
        new_category_was_added_successfully: 'New category was added successfully',
        the_category_was_updated_successfully: 'The category was updated successfully',
        the_category_was_deleted_successfully: 'The category was deleted successfully',
        password_is_required: 'Password is required',
        Inactive_Irrigation_Forms: 'Inactive Irrigation Forms',
        inspections: "inspections",
        Inspections: "Inspections",
        Inspection_Forms: 'Inspection Forms',
        Mail_Surveys: 'Mail Surveys',
        Test_Forms_prior_to_BSI_: 'Test Forms prior to BSI',
        no_media_assets_associated_with_this_property: 'No media assets associated with this property',
        no_assets_in_this_media_category: 'No assets in this Media category',
        copy_ccn: 'Copy CCN',
        ccn_copied: 'CCN copied!',
        info: "Info",
        devices_by_due_date: 'Devices by due date',
        past_due_date: 'Past due date',
        installs: 'Installs',
        replacements: 'Replacements',
        non_compliant_devices: 'non-compliant devices',
        letter_templates: 'Letter Templates',
        letter_templates_for_your_organization: 'Letter templates for your organization',
        template_deletion: 'Template deletion',
        template_was_deleted_successfully: 'Template was deleted successfully',
        you_are_about_to_delete_the_letter_template: 'Are you sure you want to delete this letter template?',
        letter_template_addition: 'Letter template addition',
        new_letter_template_was_added_successfully: 'New letter template was added successfully',
        letter_template_updated: 'Letter template was updated',
        letter_template_was_successfully_updated: 'Letter template was updated successfully',
        testers: "Testers",
        test_kit: "Test Kit",
        test_kits: "Test Kits",
        calibration_date: 'Calibration date',
        certification_date: "Certification date",
        certificate: "Certificate",
        search_for_test_kits: "Search for test kits",
        export_test_kits: "Export test kits",
        properties_with_possible_duplicate_address: 'Properties with possible duplicate address',
        search_letters: 'Search Letters',
        "Search Letters": 'Search Letters',
        search_letters_for_your_organization: 'Search letters for your organization',
        start_typing: 'Start typing ...',
        status: 'Status',
        generated_date: 'Generated Date',
        generation_date: 'Generation Date',
        label: 'Label',
        sender: 'Sender',
        generated: 'Generated',
        processed: 'Processed',
        By: 'By',
        search_properties: "Search properties",
        search_devices: "Search devices",
        filters: "Filters",
        search_for_devices: 'Search for devices',
        search_by_serial_or_meter_number: "Search by serial or meter number",
        search_by_serial_meter_or_premise_number: "Search by serial, meter or premise number",
        meter: "Meter",
        initial_date: "Initial Date",
        GenerateLetters: "Generate Letters",
        generate_letters_for_your_organization: "Generate letters for your organization",
        add_template: 'Add template',
        add_letter_template: 'Add Letter template',
        add_new_letter_template: 'Add new letter template',
        insert: 'Insert',
        template_name: 'Template Name',
        letter_type: 'Letter Type',
        program_name: 'Program Name',
        letter_header: 'Letter Header',
        letter_template_details: 'Letter template details',
        view_edit_letter_template_details: 'View, edit letter template details',
        file: 'File',
        generated_at: 'Generated at',
        total_letters: 'Total letters',
        attached_files: 'Attached files',
        actions: "Actions",
        queue_number: 'Queue number',
        created_date: 'Created date',
        print: 'Print',
        created_by: 'Created by',
        no_file: 'No file',
        queued: 'Queued',
        list_properties_for_template: 'List Properties for Template',
        per_page: 'Per page',
        last_notification: 'Last Notification',
        last_notification_sent: 'Last Notification Sent',
        selected: 'Selected',
        select_all: 'Select all',
        remove_selected: 'Remove Selected',
        remove_all: 'Remove All',
        previous: 'Previous',
        skip: 'Skip',
        process_queue: 'Process Queue',
        next: 'Next',
        close: 'Close',
        queue: 'Queue',
        queue_details: 'Queue details',
        queue_name: 'Queue name',
        batch_size: 'Batch size',
        grouping_strategy: 'Grouping strategy',
        notification: 'Notification',
        sent: 'Sent',
        batch_progress: 'Batch Progress',
        batches_processed: 'Batches processed',
        letters_were_added: 'Letters were added',
        letters_were_added_successfully: 'Letters were added successfully',
        queue_was_processed: 'Queue was processed',
        queue_was_marked_as_processed_successfully: 'Queue was marked as processed successfully',
        created_at: 'Created at',
        SearchLetters: 'Search Letters',
        code: 'Code',
        sort: 'Sort',
        device_state: 'Device State',
        Compliant: 'Compliant',
        notes: 'Notes',
        last_test_status: 'Last Test Status',
        preview: 'Preview',
        company: 'Company',
        search_for_testers: 'Search for testers',
        report: 'Report',
        test_report: 'Test report',
        last_test_date: 'Last test date',
        premise_number: 'Premise number',
        quick_print: 'Quick Print',
        selected_devices: 'Selected Devices',
        due_date_of_selected_devices: 'Due date of selected devices',
        this_data_is_exclusively_based_on_the_most_recent_test_result: 'This data is exclusively based on the most recent test result',
        device_modified: 'Device Modified',
        "required_field!": "required_field!",
        "Letters": "Letters",
        "Templates": "Templates",
        "Queues": "Queues",
        "Testers": "Testers",
        "State Standards": "State Standards",
        "System": "System",
        search_queues: 'Search queues',

        activate_devices_previously_deactivated_with_this_property: 'Activate devices previously deactivated with this property?',
        deactivate_devices_associated_with_this_property: 'Deactivate devices associated with this property?',
        loading: 'Loading...',
        old_value: 'Old Value',
        new_value: 'New Value',
        "Enter Test Report": 'Enter Test Report',
        "Cart": 'Cart',
        balance: 'Balance',
        "Testing": 'Testing',
        "Licenses": 'Licenses',
        "Test Kits": 'Test Kits',
        "Tests": 'Tests',
        "Payments/Receipts": 'Payments/Receipts',
        "Payment Methods": "Payment Methods",
        "Receipts": "Receipts",
        "BSI User Admin": "BSI User Admin",
        'Manage': 'Manage',
        'Add Test Report': 'Add Test Report',
        'Testing Company': 'Testing Company',
        recent_backflow_tests: 'Recent Backflow Tests',
        test_date: 'Test Date',
        submitted: 'Submitted',
        company_licenses: 'Company Licenses',
        expiring_testing_licenses: 'Expiring Testing Licenses',
        expiring_tester_licenses: 'Expiring Tester Licenses',
        expiring_test_kits: 'Expiring Test Kits',
        updating_test_kit: 'Updating test kit',
        out_of: 'out of',
        rank_for_number_of_devices_tested_for_all_BSI_customers: 'rank for number of devices tested for all BSI customers',
        most_frequently_tested_cities: 'Most frequently tested cities',
        devices_tested_this_year_vs_last_year: 'Devices Tested This Year vs Last Year',
        progress: 'Progress',
        devices_tested_this_month: 'Devices Tested this month',
        no_devices_tested_this_month: 'No devices tested this month',
        devices_tested_last_12_months: 'Devices Tested last 12 months',
        no_devices_tested_last_12_months: 'No devices tested last 12 months',
        go_back_to_property_list: 'Go back to property list',
        filing_fee: 'Filing Fee',
        select_device: 'Select Device',
        go_to_cart: 'Go to cart',
        filter_by_type: 'Filter by Type',
        search_by_sn: 'Search by SN',
        type_here: 'Type here ...',
        reset_filters: 'Reset filters',
        test_in_cart: 'Test in cart',
        price: 'Price',
        edited: 'Edited',
        no_previous_tests_found: 'No previous tests found',
        enter_test: 'Enter test',
        device_edit_replace_done_this_session: 'Device edit/replaces done this session',
        working: 'working...',
        go_back_to_device_list: 'Go back to device list',
        test_report_was_added_to_cart: 'Test report was added to cart',
        enter_test_for_another_device: 'Enter test for another device',
        select_a_different_property: 'Select a different property',
        initial_time: 'Initial time',
        could_not_find_valid_testers_for: 'Could not find valid testers for',
        please_ensure_there_are_valid_testers_licenses:'please ensure there are valid testers licenses',
        could_not_find_valid_test_kits_for: 'Could not find valid test kits for',
        please_ensure_there_are_valid_test_kits:'please ensure there are valid test kits',
        please_select_test_date_to_see_valid_testers_and_test_kits: 'Please select a test date to see valid testers and test kits',
        initial_test: 'Initial Test',
        either_the_check_value_or_state_does_not_pass: 'Either the check value or state does not pass',
        select_valve_state: 'Select valve state ...',
        "Closed Tight/Held": "Close Tight/Held",
        "Leaked": "Leaked",
        "Opened": "Opened",
        "Did Not Open": 'Did Not Open',
        "Did not open": 'Did not open',
        "Closed Tight": 'Closed Tight',
        "Did not close": 'Did not close',
        "Open": 'Open',
        "Check Valve #1": 'Check Valve #1',
        "Check Valve #2": 'Check Valve #2',
        "Check Valve": 'Check Valve',
        "Air Inlet": 'Air Inlet',
        "Test RV": 'Test RV',
        "Relief Valve": 'Relief Valve',
        was_a_repair_performed: 'Was a repair performed',
        final_date: 'Final Date',
        final_time: 'Final time',
        final_test: 'Final Test',
        please_list_additional_comments_or_repairs: 'Please list additional comments or repairs made / materials (parts) used',
        is_assembly_installed_accordance_with_manufacturer_recommendations: 'Is the assembly installed in accordance with manufacturer recommendations and / or local codes? Y/N',
        i_certify_all_info_submitted_is_true: 'I certify that all information submitted for this report is true and accurate, and passes all current state/local standards.',
        tester_information: 'Tester Information',
        please_choose_a_test_date: 'Please choose a test date',
        please_select_a_tester: 'Please select a tester',
        please_select_a_test_kit: 'Please select a test kit',
        submit_test_and_add_to_cart: 'Submit Test and Add to Cart',
        cart: 'Cart',
        saved: 'Saved',
        deselect_all: "Deselect All",
        save_for_later: 'Save for later',
        checkout: 'Checkout',
        move_to_cart: 'Move to cart',
        no_items_in: 'No items in',
        you_are_about_to_delete_items: 'You are about to delete item(s)!',
        items_moved: 'item(s) moved',
        save_items_for_later: 'Save items for later',
        items_deleted: 'item(s) deleted',
        cost: 'Cost',
        item: 'Item',
        submit_order: 'Submit Order',
        choose_payment_method: 'Choose payment method',
        you_can_use_balance_card_or_both: 'You can use your balance, debit/credit card or both together',
        available_balance: 'Available Balance',
        apply_balance: 'Apply balance',
        do_not_use_balance: 'Do not use balance',
        new_balance_after_checkout: 'New Balance After Checkout',
        balance_not_applied: 'Balance not applied',
        review_and_submit: 'Review & Submit',
        items_in_your_cart: 'Item(s) in Your Cart',
        receipt: 'Receipt',
        order_summary: 'Order Summary',
        order_total: 'Order total',
        balance_used: 'Balance used',
        cc_charge: 'CC charge',
        bsi_credit_applied: 'BSI Credit Applied',
        generating_receipt_is_taking_longer: 'Generating a receipt is taking longer than expected. Please visit the Manage Receipts page to view the PDF shortly.',
        download_receipt: 'Download receipt',
        review_order: 'Review Order',
        submit_order_without_payment: 'Submit Order without payment',
        please_provide_reason_for_processing_without_payment: 'Please provide a reason for processing without a payment',
        please_provide_reason_for_using_balance: 'Please provide a reason for using the balance',
        reason_for_processing_order_without_payment: 'Reason for processing order without payment?',
        you_are_impersonating_tc_user_confirm_using_companys_credit_card: 'You are impersonating a TC user. Please confirm using the company\'s credit card to pay the remaining amount due',
        confirm_credit_card_payment: 'Confirm credit card payment',
        charge_credit_card: 'Charge Credit Card',
        payment_error: 'Payment Error',
        company_balance_should_not_be_negative_cannot_apply: 'Company balance should not be negative, cannot apply',
        payment_card: 'Payment Card',
        credit_card_info_will_not_be_saved:'Credit Card information will not be saved!',
        city_state_province_zip: 'City/State(Province)/ZIP',
        last_four: 'Last Four',
        no_logo: 'No Logo',
        no_card_on_file: 'No card on file',
        change_card: 'Change Card',
        card_number: 'Card Number',
        yyyy: 'YYYY',
        billing_address: 'Billing Address',
        state_province: 'State/Province',
        select_state_province: 'Select a state/province',
        zip_postal_code: 'ZIP/Postal Code',
        save_to_profile: 'Save to profile?',
        saved_credit_cards_are_available_for_use_by_all_tc_users: 'Saved credit cards are available for use by all testing company users when checking out',
        nickname_optional: 'Nickname (Optional)',
        default_payment_method: 'Default Payment Method',
        add_card: 'Add Card',
        save_and_use_card: 'Save and use card',
        use_card: 'Use card',
        payment_method: 'Payment Method',
        make_default: 'Make Default',
        choose_card: 'Choose Card',
        change_payment_method: 'Change Payment Method',
        add_payment_method: 'Add Payment Method',
        items: 'items',
        subtotal: 'Subtotal',
        balance_applied: 'Balance applied',
        remaining: 'Remaining',
        total_due: "Total due",
        the_selected_credit_card_will_be_charged: 'The selected credit card will be charged amount due',
        cannot_checkout_amount_due_exceeds_applied_balance: 'Cannot checkout, amount due exceeds applied balance and there is no credit card on file',
        amount_due_is_paid_with_balance: 'Amount due is paid with balance, credit card will not be charged',
        checkout_failed: 'Checkout Failed!',
        reason_why_is_required_when_processing_without_a_balance: 'Reason why is required when processing without a balance!',
        cannot_find_valid_payment_type: 'Cannot find valid payment type!',
        you_must_have_a_card_on_file_or_provide_payment_details: 'You must have a card on file or provide payment details as the invoice amount exceeds your available balance.',
        please_go_back_and_provide_credit_card_info_for_payment: 'Please go back and provide credit card information for payment',
        checkout_successful: 'Checkout Successful!',
        checkout_was_successful: 'Checkout was successful',
        back: 'Back',
        searching_for_properties_failed: 'Searching for properties failed',
        searching_for_devices_failed: 'Searching for devices failed!',
        initial_date_is_required: 'Initial date is required!',
        initial_time_is_required: 'Initial time is required',
        final_time_is_required: 'Final time is required',
        initial_date_cannot_be_in_the_future: 'Initial Date cannot be in the future!',
        final_test_date_cannot_be_in_the_future: 'Final Test Date cannot be in the future!',
        initial_date_cannot_be_after_final_date: 'Initial Date cannot be after final date',
        you_have_marked_this_test_failed_but_it_actually_passed: 'You have marked this test as fail, but it actually passed. Enter this test anyway?',
        you_have_marked_this_test_pass_but_it_actually_failed: 'You have marked this test as pass, but it actually failed. Enter this test anyway?',
        failed_warning: 'Failed Warning',
        none: 'none',
        list_company_for_customers: 'List Company for customers',
        company_contact_name: 'Company Contact Name',
        no_elements_found_consider_changing_search_query: 'Oops! No elements found. Consider changing the search query.',
        type_to_search: 'Type to search',
        update_testing_company_profile: 'Update testing company profile',
        testing_company_profile_was_updated_successfully: 'Testing company profile was updated successfully',
        failed_to_update_testing_company_profile: 'Failed to update testing company profile',
        license: 'License',
        manage_testing_company_licenses: 'Manage Testing Company\'s Licenses',
        add_license: 'Add license',
        search_by_number: 'Search by number',
        remove: 'Remove',
        you_are_about_to_remove_this_license: 'You are about to remove this license!',
        new_company_license_was_added_successfully: 'New company license was added successfully',
        failed_to_add_new_company_license: 'Failed to add new company license',
        update_license: 'Update license',
        license_was_updated_successfully: 'License was updated successfully',
        failed_to_add_update_license: 'Failed to add update license',
        remove_license: 'Remove license',
        license_was_removed_successfully: 'License was removed successfully',
        failed_to_remove_license: 'Failed to remove license',
        license_type: 'License type',
        select_license_file: 'Select License file',
        license_file_not_required: 'License file is not required',
        add_test_kit: 'Add Test Kit',
        edit_test_kit: 'Edit Test Kit',
        you_are_about_to_disable_this_test_kit: 'You are about to disable this Test Kit!',
        yes_disable_it: 'Yes, disable it!',
        test_kit_name: 'Test Kit Name',
        upload_certificate: 'Upload certificate',
        not_vetted: 'Not vetted',
        disable_test_kit: 'Disable test kit',
        test_kit_was_disabled_successfully: 'Test kit was disabled successfully',
        failed_to_disable_test_kit: 'Failed to disable test kit',
        enable_test_kit: 'Enable test kit',
        test_kit_was_enabled_successfully: 'Test kit was enabled successfully',
        failed_to_enable_test_kit: 'Failed to enable test kit',
        new_test_kit_added: 'New test kit added',
        new_test_kit_was_successfully_added: 'New test kit was successfully added',
        failed_to_add_new_test_kit: 'Failed to add new test kit',
        test_kit_updated: 'Test kit updated',
        test_kit_was_updated_successfully: 'Test kit was updated successfully',
        failed_to_update_test_kit: 'Failed to update test kit',
        disable: 'Disable',
        enable: 'Enable',
        add_tester: 'Add tester',
        edit_tester: 'Edit tester',
        edit_license: 'Edit license',
        modify_tester: 'Modify Tester',
        no_tester_has_been_selected: 'No tester has been selected!',
        tester_has_been_added_successfully: 'Tester has been successfully added!',
        failed_to_add_tester: 'Failed to add tester!',
        update_tester: 'Update tester',
        tester_was_updated_successfully: 'Tester was updated successfully!',
        failed_to_update_tester: 'Failed to update tester!',
        deactivate_tester: 'Deactivate tester',
        tester_was_deactivated_successfully: 'The tester was deactivated successfully',
        failed_to_deactivate_tester: 'Failed to deactivate tester',
        activate_tester: 'Activate tester',
        tester_was_activated_successfully: 'The tester was activated successfully',
        failed_to_activate_tester: 'Failed to activate tester',
        add_tester_license: 'Add tester license',
        license_was_added_successfully: 'License was added successfully',
        modify_tester_license: 'Modify Tester License',
        no_tester_license_has_been_selected: 'No tester license has been selected!',
        update_tester_license: 'Update tester license',
        manage_testers: 'Manage Testers',
        search_for_testers_by_first_name_last_name_or_email: 'Search for testers by first name, last name or email',
        no_licenses: 'No licenses',
        you_are_about_to_deactivate_this_tester: 'You are about to deactivate this tester!',
        yes_deactivate_this_tester: 'Yes, deactivate this tester!',
        yes_remove_the_license: 'Yes, remove the license!',
        search_test: 'Search Tests',
        bulk_download_selected_tests: 'Bulk Download Selected Tests',
        clear_selections:'Clear Selections',
        max_number_of_tests_selected_for_bulk_download: 'Max number of tests selected for bulk download',
        search_for_a_property_by_address_name_or_ccn: 'Search for a property by address, name or CCN',
        bulk_download: 'Bulk download',
        entered_by: 'Entered by',
        date_submitted: 'Date submitted',
        no_pdf_found: 'No PDF Found',
        pdf_for_test_not_found_please_try_regenerating_or_contact_support: 'PDF for test not found, please try regenerating or contact support.',
        download: 'Download',
        back_to_list: 'Back to List',
        test_information: 'Test Information',
        submitted_date: 'Submitted Date',
        initial: 'Initial',
        final: 'Final',
        additional_comments_or_repairs_made_material_used: 'Additional Comments Or Repairs Made/Materials (Parts) Used',
        no_media_uploaded: 'No media uploaded',
        items_deleted_from_cart: 'Items deleted from cart',
        payment_method_was_added_successfully: 'Payment method was added successfully',
        failed_to_add_payment_method: 'Failed to add payment method',
        remove_payment_method: 'Remove payment method',
        payment_method_was_removed_successfully: 'Payment method was removed successfully',
        failed_to_add_license: 'Failed to add license',
        failed_to_edit_license: 'Failed to edit license',
        transaction_not_found: 'Transaction Not Found',
        no_transaction_found_for: 'No Transaction found for ',
        please_contact_it_support: 'Please contact IT Support',
        error_occurred_getting_transaction_by_uid: 'Error occurred getting transaction by uid',
        receipt_generation: 'Receipt Generation',
        this_receipt_is_already_scheduled_to_be_generated: 'This receipt is already scheduled to be generated',
        this_receipt_does_not_need_regenerated: 'This receipt does not need regenerated',
        schedule_receipt_generation: 'Schedule receipt generation',
        default_card: 'Default Card',
        you_are_about_to_remove_this_payment_method: 'You are about to remove this payment method.',
        company_balance: 'Company Balance',
        payment_methods: 'Payment Methods',
        saved_credit_cards_are_available_for_use_by_all_testing_company_users: 'Saved credit cards are available for use by all testing company users when checking out',
        add_new_card: 'Add new card',
        no_payment_methods_found_please_add_new_card: 'No payment methods found. Please add a new card.',
        add_new_payment_card: 'Add new payment card',
        manage_receipts: 'Manage Receipts',
        search_receipts_by_username: 'Search receipts by Username',
        bsi_checkout_comment: 'BSI Checkout Comment',
        external_transaction_id: 'External Transaction ID',
        total_price: 'Total Price',
        paid_by_balance: 'Paid by Balance',
        paid_by_credit_card: 'Paid by Credit Card',
        paid_by_bsi_credit: 'Paid by BSI Credit',
        user_role: 'User Role',
        new_password: 'New Password',
        form_fields_should_not_be_blank_please_correct: 'Form fields should not be blank! Please correct the form errors.',
        failed_to_change_password: 'Failed to change password',
        block: 'Block',
        unblock: 'Unblock',
        user_activation: 'User activation',
        user_was_activated_successfully: 'User was activated successfully',
        failed_activate_user: 'Failed to activate user',
        user_deactivation: 'User deactivation',
        user_was_deactivated_successfully: 'User was deactivated successfully',
        failed_to_deactivate_user: 'Failed to deactivate user',
        user_status: 'User status',
        user_was_blocked_successfully: 'User was blocked successfully',
        failed_to_block_user: 'Failed to block user',
        user_was_unblocked_successfully: 'User was unblocked successfully',
        failed_to_unblock_user: 'Failed to unblock user',
        user_addition: 'User addition',
        new_user_was_added_successfully: 'New user was added successfully',
        failed_to_add_new_user: 'Failed to add new user',
        user_details_update: 'User details update',
        user_details_were_updated_successfully: 'User details were updated successfully',
        failed_to_update_user_details: 'Failed to update user details',
        please_select_test_date: 'Please select test date',
        not_indicated: 'Not indicated',
        edit_profile: 'Edit Profile',
        confirm_new_password: 'Confirm New Password',
        back_to_property_details: 'Back to Property Details',
        submit: 'Submit',
        last_test_status_pass: 'Last test status: Pass',
        last_test_status_failed: 'Last test status: Failed',
        sorry_but_you_dont_have_all_necessary_permissions_to_generate_letters: 'Sorry, but you don\'t have all necessary permissions to generate letters',
        letters_queues: 'Letters Queues',
        back_to_template_details: 'Back to Template Details',
        read_only: 'read only',
        please_select_user_from_the_list: 'Please select User from the list',
        no_users_with_this_role: 'No users with this role',
        failed_to_add_new_comment: 'Failed to add new comment',
        failed_to_grant_extension: 'Failed to grant extension',
        failed_to_move_device: 'Failed to move device',
        failed_to_replace_device: 'Failed to replace device',
        failed_to_change_due_date: 'Failed to change due date',
        failed_to_activate_all_devices: 'Failed to activate all devices',
        failed_to_deactivate_all_devices: 'Failed to deactivate all devices',
        failed_to_activate_device: 'Failed to activate device',
        failed_to_deactivate_device: 'Failed to deactivate device',
        failed_to_add_new_device: 'Failed to add new device',
        failed_to_update_device: 'Failed to update device',
        due_date_forwarded_by_above_passing_test: 'Due date forwarded by above passing test.',
        due_date_forwarded_by_nightly_script_that_forwards_due_dates: 'Due date forwarded by nightly script that forwards due dates for the coming year.',
        update_preferences: 'Update preferences',
        preferences_were_updated_successfully: 'Preferences were updated successfully',
        failed_to_update_preferences: 'Failed to update preferences',
        profile_was_updated_successfully: 'Profile was updated successfully',
        failed_to_update_profile: 'Failed to update profile',
        failed_to_add_new_permission: 'Failed to add new permission',
        failed_to_revoke_permission: 'Failed to revoke permission',
        failed_to_revoke_role_from_the_user: 'Failed to revoke role from the user',
        failed_to_add_role_to_the_user: 'Failed to add role to the user',
        failed_to_add_new_role: 'Failed to add new role',
        failed_to_update_role: 'Failed to update role',
        failed_to_delete_role: 'Failed to delete role',
        failed_to_add_new_category: 'Failed to add new category',
        failed_to_update_category: 'Failed to update category',
        failed_to_delete_category: 'Failed to delete category',
        describes_the_cost_of_a_backflow_test: 'Describes the cost of a backflow test.',
        all_rights_reserved: 'All rights Reserved',
        privacy_policy: 'Privacy Policy',
        terms_of_use: 'Terms of use',
        receipt_detail: 'Receipt Detail',
        receipt_is_being_generated: 'Receipt is being generated...',
        download_separated_by_address: 'Download Separated by Address',
        sort_by: 'Sort by',
        no_transaction_found: 'No Transaction Found',
        thank_you_for_adding_your_test_kit_gauge: 'Thank you for adding your test kit gauge / calibration information.',
        if_you_did_not_upload_certificate_to_us_you_must_send_in_a_copy: 'If you did not upload the certificate to us, you must now send in a copy of your current test kit calibration certificate to BSI by email',
        or_fax: ' or by fax',
        you_can_upload_a_copy_directly_via: 'Alternatively, you can upload a copy of the certificate directly via',
        by_using_the_button: 'by using the button',
        please_reference_your_company_name_in_the_subject: 'Please reference your company\'s name in the subject line of the email or fax cover sheet.',
        this_will_expedite_verification_of_your_test_kit: 'This will expedite the verification of your test kit.',
        please_note_you_will_not_be_able_to_file_backflow_reports_until: 'Please note - you will not be able to file backflow reports for this test kit until a copy of the certificate has been received and its calibration status has been verified.',
        authorize_net_verified_merchant_seal: 'Authorize.net Verified Merchant Seal',
        you_can_checkout_using_bsi_online_with_confidence_we_have_partnered_with: 'You can checkout using BSI Online with confidence. We have partnered with',
        leading_payment_gateway_since_to_accept_credit_cards: ' a leading payment gateway since 1996, to accept credit cards and electronic check payments safely and securely for our customers.',
        authorize_net_payment_gateway_manages_the_complex_routing_of_information: 'The Authorize.Net Payment Gateway manages the complex routing of sensitive customer information through the electronic check and credit card processing networks. See an',
        online_payments_diagram: 'online payments diagram',
        to_see_how_it_works: 'to see how it works.',
        the_company_adheres_to_strict_industry_standards_for_payment_processing_including: 'The company adheres to strict industry standards for payment processing, including',
        ssl_technology_for_secure_ip_transactions: '128-bit Secure Sockets Layer (SSL) technology for secure Internet Protocol (IP) transactions.',
        industry_leading_encryption_hardware_and_software_methods_to_protect: 'Industry leading encryption hardware and software methods and security protocols to protect customer information.',
        compliance_with_pci_dss: 'Compliance with the Payment Card Industry Data Security Standard (PCI DSS).',
        for_additional_info_regarding_the_privacy_of_your_sensitive_cardholder_data: 'For additional information regarding the privacy of your sensitive cardholder data, please read the Authorize.Net',
        bsi_online_is_registered_with_authorize_net: 'BSI Online is registered with the Authorize.Net Verified Merchant Seal program.',
        "Skip this recommendation": "Skip this recommendation",
        "Use selected address": "Use selected address",
        "Address was not found in USPS data": "Address was not found in USPS data",
        "Update mailing address": "Update mailing address",
        "Mailing address was updated successfully": "Mailing address was updated successfully",
        "Failed to update mailing address": "Failed to update mailing address",
        "Update physical address": "Update physical address",
        "Physical address was updated successfully": "Physical address was updated successfully",
        "Failed to update physical address": "Failed to update physical address",
        "Letter includes device with hazard": "Letter includes device with hazard",
        "role from": 'role from',
        "Link(s) to a file(s) for print: ": "Link(s) to a file(s) for print: ",
        "Mark this queue as printed?": "Mark this queue as printed?",
        "Generate": "Generate",
        "Delete All": "Delete All",
    },

    fr: {
        "Remove queue": "Supprimer la file d'attente",
        "Queue was removed successfully":"La file d'attente a été supprimée avec succès",
        "Failed to remove a queue!":"Impossible de supprimer une file d'attente !",
        "Failed to mark queue as processed!":"Impossible de marquer la file d'attente comme traitée !",
        "Printed":"Imprimé",
        "Mark as Printed":"Marquer comme imprimé",
        "Delete":"Supprimer",
        "Are you sure?":"Es-tu sûr?",
        "You are about to remove the queue!":"Vous êtes sur le point de supprimer la file d'attente !",
        "Delete All": "Supprimer tout",
        "Generate": "Générer",
        GenerateLetters: "Générer des lettres",
        generate_letters_for_your_organization: "Générer des lettres pour votre organisation",
        "Mark this queue as printed?": "Marquer cette file d’attente comme imprimée ?",
        "Link(s) to a file(s) for print: ": "Lien(s) vers un ou plusieurs fichiers à imprimer :",
        "Update physical address": "Mettre à jour l'adresse physique",
        "Physical address was updated successfully": "L'adresse physique a été mise à jour avec succès",
        "Failed to update physical address": "Échec de la mise à jour de l'adresse physique",
        "Update mailing address": "Mettre à jour l'adresse postale",
        "Mailing address was updated successfully": "L'adresse postale a été mise à jour avec succès",
        "Failed to update mailing address": "Échec de la mise à jour de l'adresse postale",
        "Skip this recommendation": "Ignorer cette recommandation",
        "Use selected address": "Utiliser l'adresse sélectionnée",
        "Address was not found in USPS data": "L'adresse n'a pas été trouvée dans les données USPS",
        activate_devices_previously_deactivated_with_this_property: 'Activer les appareils précédemment désactivés avec cette propriété ?',
        deactivate_devices_associated_with_this_property: 'Désactiver les appareils associés à cette propriété ?',
        loading: 'Chargement...',
        old_value: 'Ancienne valeur',
        new_value: 'Nouvelle valeur',
        "Enter Test Report": "Entrer le rapport de test",
        "Cart": 'Chariot',
        balance: 'Solde',
        "Testing": 'Contrôle',
        "Licenses": 'Licences',
        "Test Kits": 'Kits de tests',
        "Tests": 'Contrôles',
        "Payments/Receipts": 'Paiements/Reçus',
        "Payment Methods": 'Méthodes de payement',
        "Receipts": 'Reçus',
        "BSI User Admin": "Administrateur utilisateur BSI",
        'Manage': 'Gérer',
        'Add Test Report': 'Ajouter un rapport de test',
        'Testing Company': 'Entreprise chargées des contrôles',
        recent_backflow_tests: 'Test anti-retour récent',
        test_date: 'Date du test',
        submitted: 'Soumis',
        company_licenses: 'Licences d\'entreprise',
        expiring_testing_licenses: 'Expiration des licences de test',
        expiring_tester_licenses: 'Expiration des licences de testeur',
        expiring_test_kits: 'Kits de test expirés',
        updating_test_kit: 'Mettre à jour le kit de test',
        out_of: 'sur',
        rank_for_number_of_devices_tested_for_all_BSI_customers: 'classement du nombre de dispositifs testés pour tous les clients BSI',
        most_frequently_tested_cities: 'Villes les plus récemment testées',
        devices_tested_this_year_vs_last_year: 'Dispositifs testés cette année par rapport à l\'année dernière',
        progress: 'Progrès',
        devices_tested_this_month: 'Dispositifs testés ce mois-ci',
        no_devices_tested_this_month: 'Aucun dispositifs testé ce mois-ci',
        devices_tested_last_12_months: 'Dispositifs testés au cours des 12 derniers mois',
        no_devices_tested_last_12_months: 'Aucun dispositifs testé au cours des 12 derniers mois',
        go_back_to_property_list: 'Revenir à la liste des propriétés',
        filing_fee: 'Taxe de dépôt',
        select_device: 'Choisir le dispositif',
        go_to_cart: 'Aller au panier',
        filter_by_type: 'Filtrer par type',
        search_by_sn: 'Recherche par NS',
        type_here: 'Écrivez ici ...',
        reset_filters: 'Réinitialiser les filtres',
        test_in_cart: 'Test dans le panier',
        price: 'Prix',
        edited: 'Édité',
        no_previous_tests_found: 'Aucun test précédent trouvé',
        enter_test: 'Entrez le test',
        device_edit_replace_done_this_session: 'Modification/remplacement de le dispositif effectué cette session',
        working: 'actif...',
        last_test_date: 'Date du dernier examen',
        go_back_to_device_list: 'Revenir à la liste de dispositifs',
        test_report_was_added_to_cart: 'Le rapport de test a été ajouté au panier',
        enter_test_for_another_device: 'Entrez le test pour un autre dispositif',
        select_a_different_property: 'Sélectionnez une autre propriété',
        initial_time: 'Heure initiale',
        could_not_find_valid_testers_for: 'Impossible de trouver des testeurs valides pour',
        please_ensure_there_are_valid_testers_licenses:'veuillez vous assurer qu\'il existe des licences de testeurs valides',
        could_not_find_valid_test_kits_for: 'Impossible de trouver des kits de test valides pour',
        please_ensure_there_are_valid_test_kits:'veuillez vous assurer qu\'il existe des kits de test valides',
        please_select_test_date_to_see_valid_testers_and_test_kits: 'Veuillez sélectionner une date de test pour voir les testeurs et kits de test valides',
        initial_test: 'Test initial',
        either_the_check_value_or_state_does_not_pass: 'Soit la valeur de contrôle, soit l\'état ne passe pas',
        select_valve_state: 'Sélectionner l\'état de la vanne ...',
        "Closed Tight/Held": "Fermer/Maintenir",
        "Leaked": "Fuite",
        "Opened": "Ouverte",
        "Did Not Open": 'N\'a pas ouvert',
        "Did not open": 'N\'a pas ouvert',
        "Closed Tight": 'Bien fermé',
        "Did not close": 'N\'a pas fermé',
        "Open": 'Ouvrir',
        "Check Valve #1": 'Clapet anti-retour #1',
        "Check Valve #2": 'Clapet anti-retour #2',
        "Check Valve": 'Clapet anti-retour',
        "Air Inlet": 'Entrée d\'air',
        "Test RV": 'Test RV',
        "Relief Valve": 'Soupape de décharge',
        was_a_repair_performed: 'Une réparation a-t-elle été effectuée',
        final_date: 'Date finale',
        final_time: 'Dernière heure',
        final_test: 'Test final',
        please_list_additional_comments_or_repairs: 'Veuillez énumérer les commentaires supplémentaires ou les réparations effectuées / les matériaux (pièces) utilisés',
        is_assembly_installed_accordance_with_manufacturer_recommendations: 'L\'ensemble est-il installé conformément aux recommandations du fabricant et/ou aux codes locaux ? O/N',
        i_certify_all_info_submitted_is_true: 'Je certifie que toutes les informations soumises pour ce rapport sont vraies et exactes et répondent à toutes les normes nationales/locales en vigueur.',
        tester_information: "Informations sur le testeur",
        please_choose_a_test_date: 'Veuillez choisir une date de test',
        please_select_a_tester: 'Veuillez sélectionner un testeur',
        submit_test_and_add_to_cart: 'Soumettre le test et ajouter au panier',
        please_select_a_test_kit: 'Veuillez sélectionner un kit de test',
        cart: 'Chariot',
        saved: 'Enregistrés',
        deselect_all: "Tout déselectionner",
        save_for_later: 'Garder pour plus tard',
        checkout: 'Vérifier',
        move_to_cart: 'Passer au panier',
        no_items_in: 'Aucun élément dans',
        you_are_about_to_delete_items: 'Vous êtes sur le point de supprimer des éléments!',
        items_moved: 'élément(s) déplacé(s)',
        save_items_for_later: 'Enregistrer les éléments pour plus tard',
        items_deleted: 'élément(s) supprimé(s)',
        cost: 'Coût',
        item: 'Article',
        submit_order: 'Soumettre la commande',
        choose_payment_method: 'Choisissez le mode de paiement',
        you_can_use_balance_card_or_both: 'Vous pouvez utiliser votre solde, votre carte de débit/crédit ou les deux ensemble',
        available_balance: 'Solde disponible',
        apply_balance: 'Appliquer le solde',
        do_not_use_balance: 'N\'utilisez pas la balance',
        new_balance_after_checkout: 'Nouveau solde après le paiement',
        balance_not_applied: 'Solde non appliqué',
        review_and_submit: 'Vérifier et soumettre',
        items_in_your_cart: 'Articles dans votre panier',
        receipt: 'Reçu',
        order_summary: 'Récapitulatif de la commande',
        order_total: 'Total de la commande',
        balance_used: 'Solde utilisé',
        cc_charge: 'Frais de carte de crédit',
        bsi_credit_applied: 'Crédit BSI appliqué',
        download_receipt: 'Télécharger le reçu',
        review_order: 'Réviser la commande',
        submit_order_without_payment: 'Soumettre la commande sans paiement',
        please_provide_reason_for_processing_without_payment: 'Veuillez fournir une raison pour le traitement sans paiement',
        please_provide_reason_for_using_balance: 'Veuillez fournir une raison pour laquelle vous utilisez le solde',
        reason_for_processing_order_without_payment: 'Raison du traitement de la commande sans paiement ?',
        pdf_was_created_but_it_cannot_be_viewed_from_local_bsi_instance: '',
        you_are_impersonating_tc_user_confirm_using_companys_credit_card: 'Vous vous faites passer pour un utilisateur TC. Veuillez confirmer en utilisant la carte de crédit de l\'entreprise pour payer le montant restant dû',
        confirm_credit_card_payment: 'Confirmer le paiement par carte de crédit',
        charge_credit_card: 'Charger une carte de crédit',
        payment_error: 'Erreur de paiement',
        company_balance_should_not_be_negative_cannot_apply: 'Le solde de l\'entreprise ne doit pas être négatif, ne peut pas s\'appliquer',
        payment_card: 'Carte de paiement',
        credit_card_info_will_not_be_saved: 'Les informations de carte de crédit ne seront pas enregistrées !',
        city_state_province_zip: 'Ville/État (Province)/Code Postal',
        last_four: 'Quatre derniers',
        no_logo: 'Pas de logo',
        no_card_on_file: 'Aucune carte au dossier',
        change_card: 'Changer de carte',
        card_number: 'Numéro de carte',
        yyyy: 'AAAA',
        billing_address: 'Adresse de facturation',
        state_province: 'État/Province',
        select_state_province: 'Sélectionnez un état/une province',
        zip_postal_code: 'ZIP/Code postal',
        save_to_profile: 'Enregistrer dans le profil ?',
        saved_credit_cards_are_available_for_use_by_all_tc_users: 'Les cartes de crédit enregistrées peuvent être utilisées par tous les utilisateurs de la société de test lors du paiement',
        nickname_optional: 'Surnom (optionnel)',
        default_payment_method: 'Méthode de paiement par défaut',
        add_card: 'Ajouter une carte',
        save_and_use_card: 'Enregistrer et utiliser la carte',
        use_card: 'Utiliser la carte',
        payment_method: 'Mode de paiement',
        make_default: 'Make Default',
        choose_card: 'Choisir la carte',
        change_payment_method: 'Changer la méthode de paiement',
        add_payment_method: 'Ajouter un mode de paiement',
        items: 'articles',
        subtotal: 'Total',
        balance_applied: 'Solde appliqué',
        remaining: 'Restante',
        total_due: 'Total dû',
        the_selected_credit_card_will_be_charged: 'La carte de crédit sélectionnée sera débitée du montant dû',
        cannot_checkout_amount_due_exceeds_applied_balance: 'Paiement impossible, le montant dû dépasse le solde appliqué et aucune carte de crédit n\'est enregistrée',
        amount_due_is_paid_with_balance: 'Le montant dû est payé avec le solde, la carte de crédit ne sera pas débitée',
        checkout_failed: 'Échec du paiement!',
        reason_why_is_required_when_processing_without_a_balance: 'Raison pour laquelle est nécessaire lors d\'un traitement sans balance !',
        cannot_find_valid_payment_type: 'Impossible de trouver un type de paiement valide !',
        you_must_have_a_card_on_file_or_provide_payment_details: 'Vous devez avoir une carte enregistrée ou fournir les détails de paiement car le montant de la facture dépasse votre solde disponible.',
        please_go_back_and_provide_credit_card_info_for_payment: 'Veuillez revenir en arrière et fournir les informations de votre carte de crédit pour le paiement',
        checkout_successful: 'Paiement réussi !',
        checkout_was_successful: 'Le paiement a réussi',
        back: 'Retourner',
        searching_for_properties_failed: 'La recherche de propriétés a échoué !',
        searching_for_devices_failed: 'La recherche de dispositifs a échoué !',
        initial_date_is_required: 'La date initiale est requise !',
        initial_time_is_required: 'Initial time is required',
        final_time_is_required: 'L\'heure finale est requise',
        initial_date_cannot_be_in_the_future: 'La date initiale ne peut pas être postérieure !',
        final_test_date_cannot_be_in_the_future: 'La date du test final ne peut pas être postérieure !',
        initial_date_cannot_be_after_final_date: 'La date initiale ne peut pas être postérieure à la date finale',
        you_have_marked_this_test_failed_but_it_actually_passed: 'Vous avez marqué ce test comme échec, mais il a réussi. Participer quand même à ce test ?',
        you_have_marked_this_test_pass_but_it_actually_failed: 'Vous avez marqué ce test comme réussi, mais il a en fait échoué. Entrez quand même dans ce test ?',
        failed_warning: 'Avertissement d\'échec',
        none: 'Aucune',
        list_company_for_customers: 'Liste des sociétés pour les clients',
        company_contact_name: 'Nom du contact de l\'entreprise',
        no_elements_found_consider_changing_search_query: 'Oops! Aucun élément trouvé. Pensez à modifier la requête de recherche.',
        type_to_search: 'Tapez pour rechercher',
        update_testing_company_profile: 'Mettre à jour le profil de l\'entreprise de test',
        testing_company_profile_was_updated_successfully: 'Le profil de l\'entreprise de test a été mis à jour avec succès',
        failed_to_update_testing_company_profile: 'Échec de la mise à jour du profil de l\'entreprise de test',
        license: 'Licence',
        manage_testing_company_licenses: 'Gérer les licences de la société de tests',
        add_license: 'Ajouter une licence',
        search_by_number: 'Recherche par numéro',
        remove: 'Retirer',
        you_are_about_to_remove_this_license: 'Vous êtes sur le point de supprimer cette licence !',
        new_company_license_was_added_successfully: 'La nouvelle licence d\'entreprise a été ajoutée avec succès',
        failed_to_add_new_company_license: 'Échec de l\'ajout d\'une nouvelle licence d\'entreprise',
        update_license: 'Mettre à jour la licence',
        license_was_updated_successfully: 'La licence a été mise à jour avec succès',
        failed_to_add_update_license: 'Échec de l\'ajout de la licence de mise à jour',
        remove_license: 'Supprimer la licence',
        license_was_removed_successfully: 'La licence a été supprimée avec succès',
        failed_to_remove_license: 'Échec de la suppression de la licence',
        license_type: 'Type de licence',
        select_license_file: 'Sélectionnez le fichier de licence',
        license_file_not_required: 'le fichier de licence non requis',
        add_test_kit: 'Ajouter un kit de test',
        edit_test_kit: 'Modifier le kit de test',
        you_are_about_to_disable_this_test_kit: 'Vous êtes sur le point de désactiver ce kit de test !',
        yes_disable_it: 'Oui, désactivez-le !',
        test_kit_name: 'Nom du kit de test',
        upload_certificate: 'Télécharger le certificat',
        not_vetted: 'Non vérifié',
        disable_test_kit: 'Désactiver le kit de test',
        test_kit_was_disabled_successfully: 'Le kit de test a été désactivé avec succès',
        failed_to_disable_test_kit: 'Échec de la désactivation du kit de test',
        enable_test_kit: 'Activer le kit de test',
        test_kit_was_enabled_successfully: 'Le kit de test a été activé avec succès',
        failed_to_enable_test_kit: 'Échec de l\'activation du kit de test',
        new_test_kit_added: 'Nouveau kit de test ajouté',
        new_test_kit_was_successfully_added: 'Le nouveau kit de test a été ajouté avec succès',
        failed_to_add_new_test_kit: 'Échec de l\'ajout d\'un nouveau kit de test',
        test_kit_updated: 'Kit de test mis à jour',
        test_kit_was_updated_successfully: 'Le kit de test a été mis à jour avec succès',
        failed_to_update_test_kit: 'Échec de la mise à jour du kit de test',
        disable: 'Désactiver',
        enable: 'Activer',
        add_tester: 'Ajouter un testeur',
        edit_tester: 'Edit tester',
        edit_license: 'Modifier la licence',
        modify_tester: 'Modifier le testeur',
        no_tester_has_been_selected: 'Aucun testeur n\'a été sélectionné !',
        tester_has_been_added_successfully: 'Le testeur a été ajouté avec succès !',
        failed_to_add_tester: 'Échec de l\'ajout du testeur !',
        update_tester: 'Testeur de mise à jour',
        tester_was_updated_successfully: 'Le testeur a été mis à jour avec succès !',
        failed_to_update_tester: 'Échec de la mise à jour du testeur !',
        deactivate_tester: 'Désactiver le testeur',
        tester_was_deactivated_successfully: 'Le testeur a été désactivé avec succès',
        failed_to_deactivate_tester: 'Échec de la désactivation du testeur',
        activate_tester: 'Activer le testeur',
        tester_was_activated_successfully: 'Le testeur a été activé avec succès',
        failed_to_activate_tester: 'Échec de l\'activation du testeur',
        add_tester_license: 'Ajouter une licence de testeur',
        license_was_added_successfully: 'La licence a été ajoutée avec succès',
        modify_tester_license: 'Modifier la licence du testeur',
        no_tester_license_has_been_selected: 'Aucune licence testeur n\'a été sélectionnée !',
        update_tester_license: 'Mettre à jour la licence du testeur',
        manage_testers: 'Gérer les testeurs',
        search_for_testers_by_first_name_last_name_or_email: 'Rechercher des testeurs par prénom, nom ou email',
        no_licenses: 'Aucune licence',
        you_are_about_to_deactivate_this_tester: 'Vous êtes sur le point de désactiver ce testeur !',
        yes_deactivate_this_tester: 'Oui, désactivez ce testeur !',
        yes_remove_the_license: 'Oui, supprimez la licence !',
        search_test: 'Rechercher des tests',
        bulk_download_selected_tests: 'Téléchargement groupé des tests sélectionnés',
        clear_selections:'Effacer les sélections',
        max_number_of_tests_selected_for_bulk_download: 'Nombre maximum de tests sélectionnés pour le téléchargement groupé',
        search_for_a_property_by_address_name_or_ccn: 'Rechercher une propriété par adresse, nom ou NCC',
        bulk_download: 'Téléchargement groupé',
        entered_by: 'Entré par',
        date_submitted: 'Date de soumission',
        no_pdf_found: 'Aucun PDF trouvé',
        pdf_for_test_not_found_please_try_regenerating_or_contact_support: 'Le PDF du test est introuvable. Veuillez essayer de le régénérer ou contacter l\'assistance.',
        download: 'Télécharger',
        back_to_list: 'Retour à la liste',
        test_information: 'Informations sur les tests',
        submitted_date: 'Date de soumission',
        initial: 'Initial',
        final: 'Final',
        additional_comments_or_repairs_made_material_used: 'Commentaires supplémentaires ou réparations effectuées/matériaux (pièces) utilisés',
        no_media_uploaded: 'Aucun média téléchargé',
        items_deleted_from_cart: 'Articles supprimés du panier',
        payment_method_was_added_successfully: 'Le mode de paiement a été ajouté avec succès',
        failed_to_add_payment_method: 'Échec de l\'ajout du mode de paiement',
        remove_payment_method: 'Supprimer le mode de paiement',
        payment_method_was_removed_successfully: 'Le mode de paiement a été supprimé avec succès',
        failed_to_add_license: 'Échec de l\'ajout de la licence',
        failed_to_edit_license: 'Échec de la modification de la licence',
        transaction_not_found: 'Transaction introuvable',
        no_transaction_found_for: 'Aucune transaction trouvée pour',
        please_contact_it_support: 'Veuillez contacter le support informatique',
        error_occurred_getting_transaction_by_uid: 'Une erreur s\'est produite lors de l\'obtention de la transaction par UID',
        receipt_generation: 'Génération de reçus',
        this_receipt_is_already_scheduled_to_be_generated: 'Ce reçu est déjà programmé pour être généré',
        this_receipt_does_not_need_regenerated: 'Ce reçu n\'a pas besoin d\'être régénéré',
        schedule_receipt_generation: 'Planifier la génération de reçus',
        default_card: 'Carte par défaut',
        you_are_about_to_remove_this_payment_method: 'Vous êtes sur le point de supprimer ce mode de paiement.',
        company_balance: 'Solde de l\'entreprise',
        payment_methods: 'Méthodes de payement',
        saved_credit_cards_are_available_for_use_by_all_testing_company_users: 'Les cartes de crédit enregistrées peuvent être utilisées par tous les utilisateurs de la société de test lors du paiement',
        add_new_card: 'Ajouter une nouvelle carte',
        no_payment_methods_found_please_add_new_card: 'Aucun mode de paiement trouvé. Veuillez ajouter une nouvelle carte.',
        add_new_payment_card: 'Ajouter une nouvelle carte de paiement',
        manage_receipts: 'Gérer les reçus',
        search_receipts_by_username: 'Rechercher des reçus par nom d\'utilisateur',
        bsi_checkout_comment: 'Commentaire de paiement BSI',
        external_transaction_id: 'ID de transaction externe',
        total_price: 'Prix total',
        paid_by_balance: 'Payé par solde',
        paid_by_credit_card: 'Payé par carte de crédit',
        paid_by_bsi_credit: 'Payé par Crédit BSI',
        user_role: 'Rôle d\'utilisateur',
        new_password: 'Nouveau mot de passe',
        form_fields_should_not_be_blank_please_correct: 'Les champs du formulaire ne doivent pas être vides ! Veuillez corriger les erreurs du formulaire.',
        failed_to_change_password: 'Échec de la modification du mot de passe',
        block: 'Bloquer',
        unblock: 'Débloquer',
        user_activation: 'Activation de l\'utilisateur',
        user_was_activated_successfully: 'L\'utilisateur a été activé avec succès',
        failed_activate_user: 'Échec de l\'activation de l\'utilisateur',
        user_deactivation: 'Désactivation de l\'utilisateur',
        user_was_deactivated_successfully: 'L\'utilisateur a été désactivé avec succès',
        failed_to_deactivate_user: '\'Échec de la désactivation de l\'utilisateur',
        user_status: 'Statut de l\'utilisateur',
        user_was_blocked_successfully: 'L\'utilisateur a été bloqué avec succès',
        failed_to_block_user: 'Échec du blocage de l\'utilisateur',
        user_was_unblocked_successfully: 'L\'utilisateur a été débloqué avec succès',
        failed_to_unblock_user: 'Échec du déblocage de l\'utilisateur',
        user_addition: 'Ajout d\'utilisateur',
        new_user_was_added_successfully: 'Le nouvel utilisateur a été ajouté avec succès',
        failed_to_add_new_user: 'Échec de l\'ajout d\'un nouvel utilisateur',
        user_details_update: 'Mise à jour des détails de l\'utilisateur',
        user_details_were_updated_successfully: 'Les détails de l\'utilisateur ont été mis à jour avec succès',
        failed_to_update_user_details: 'Échec de la mise à jour des détails de l\'utilisateur',
        "Serial Number": "Numéro de série",
        "Location": "Emplacement",
        please_select_test_date: 'Veuillez sélectionner la date du test',
        not_indicated: 'Non indiqué',
        edit_profile: 'Editer le profil',
        confirm_new_password: 'Confirmer le nouveau mot de passe',
        back_to_property_details: 'Retour aux détails de la propriété',
        submit: 'Soumettre',
        Compliant: 'Conforme',
        last_test_status_pass: 'Statut du dernier test: Réussite',
        last_test_status_failed: 'Statut du dernier test : Échec',
        sorry_but_you_dont_have_all_necessary_permissions_to_generate_letters: 'Désolé, mais vous ne disposez pas de toutes les autorisations nécessaires pour générer des lettres',
        letters_queues: 'Files d\'attente de lettres',
        back_to_template_details: 'Retour aux détails du modèle',
        read_only: 'lecture seule',
        please_select_user_from_the_list: 'Veuillez sélectionner Utilisateur dans la liste',
        no_users_with_this_role: 'Aucun utilisateur avec ce rôle',
        failed_to_add_new_comment: 'Échec de l\'ajout d\'un nouveau commentaire',
        failed_to_grant_extension: 'Échec de l\'octroi de la prolongation',
        failed_to_move_device: 'Échec du déplacement le dispositif',
        failed_to_replace_device: 'Échec du remplacement le dispositif',
        failed_to_change_due_date: 'Échec de la modification de la date d\'échéance',
        failed_to_activate_all_devices: 'Échec de l\'activation de tous les dispositifs',
        failed_to_deactivate_all_devices: 'Échec de la désactivation de tous les dispositifs',
        failed_to_activate_device: 'Échec de l\'activation le dispositif',
        failed_to_deactivate_device: 'Échec de la désactivation le dispositif',
        failed_to_add_new_device: 'Échec de l\'ajout le nouvel dispositif',
        failed_to_update_device: 'Échec de la mise à jour de le dispositif',
        due_date_forwarded_by_above_passing_test: 'Date d\'échéance transmise par la réussite du test ci-dessus.',
        due_date_forwarded_by_nightly_script_that_forwards_due_dates: 'Date d\'échéance transmise par script nocturne qui transmet les dates d\'échéance pour l\'année à venir.',
        update_preferences: 'Mettre à jour les préférences',
        preferences_were_updated_successfully: 'Les préférences ont été mises à jour avec succès',
        failed_to_update_preferences: 'Échec de la mise à jour des préférences',
        profile_was_updated_successfully: 'Le profil a été mis à jour avec succès',
        failed_to_update_profile: 'Échec de la mise à jour du profil',
        failed_to_add_new_permission: 'Échec de l\'ajout d\'une nouvelle autorisation',
        failed_to_revoke_permission: 'Échec de la révocation de l\'autorisation',
        failed_to_revoke_role_from_the_user: 'Échec de la révocation du rôle de l\'utilisateur',
        failed_to_add_role_to_the_user: 'Échec de l\'ajout d\'un rôle à l\'utilisateur',
        failed_to_add_new_role: 'Échec de l\'ajout d\'un nouveau rôle',
        failed_to_update_role: 'Échec de la mise à jour du rôle',
        failed_to_delete_role: 'Échec de la suppression du rôle',
        failed_to_add_new_category: 'Échec de l\'ajout d\'une nouvelle catégorie',
        failed_to_update_category: 'Échec de la mise à jour de la catégorie',
        failed_to_delete_category: 'Échec de la suppression de la catégorie',
        describes_the_cost_of_a_backflow_test: 'Décrit le coût d\'un test de reflux.',
        all_rights_reserved: 'Tous droits réservés',
        privacy_policy: 'Politique de confidentialité',
        terms_of_use: 'Conditions d\'utilisation',
        receipt_detail: 'Détail du reçu',
        receipt_is_being_generated: 'Le reçu est en cours de génération...',
        download_separated_by_address: 'Télécharger séparé par adresse',
        sort_by: 'Trier par',
        no_transaction_found: 'Aucune transaction trouvée',
        thank_you_for_adding_your_test_kit_gauge: 'Merci d\'avoir ajouté les informations de jauge/étalonnage de votre kit de test.',
        if_you_did_not_upload_certificate_to_us_you_must_send_in_a_copy: 'Si vous ne nous avez pas téléchargé le certificat, vous devez maintenant envoyer une copie du certificat d\'étalonnage de votre kit de test actuel à BSI par e-mail',
        or_fax: ', ou par fax',
        you_can_upload_a_copy_directly_via: 'Vous pouvez également télécharger une copie du certificat directement via',
        by_using_the_button: 'en utilisant le bouton',
        please_reference_your_company_name_in_the_subject: 'Veuillez indiquer le nom de votre entreprise dans la ligne d\'objet de l\'e-mail ou de la page de garde du fax.',
        this_will_expedite_verification_of_your_test_kit: 'Cela accélérera la vérification de votre kit de test.',
        please_note_you_will_not_be_able_to_file_backflow_reports_until: 'Veuillez noter que vous ne pourrez pas déposer de rapports de reflux pour ce kit de test tant qu\'une copie du certificat n\'aura pas été reçue et que son statut d\'étalonnage n\'aura pas été vérifié.',
        authorize_net_verified_merchant_seal: 'Sceau de marchand vérifié Authorize.net',
        you_can_checkout_using_bsi_online_with_confidence_we_have_partnered_with: 'Vous pouvez payer en utilisant BSI Online en toute confiance. Nous collaborons avec',
        leading_payment_gateway_since_to_accept_credit_cards: ', une passerelle de paiement leader depuis 1996, pour accepter les cartes de crédit et les paiements par chèque électronique en toute sécurité pour nos clients.',
        authorize_net_payment_gateway_manages_the_complex_routing_of_information: 'La passerelle de paiement Authorize.Net gère le routage complexe des informations clients sensibles via les réseaux de traitement électronique des chèques et des cartes de crédit. Consultez',
        online_payments_diagram: 'diagramme de paiements en ligne',
        to_see_how_it_works: 'pour voir comment cela fonctionne.',
        the_company_adheres_to_strict_industry_standards_for_payment_processing_including: 'L\'entreprise adhère à des normes strictes de l\'industrie en matière de traitement des paiements, notamment',
        ssl_technology_for_secure_ip_transactions: 'Technologie SSL (Secure Sockets Layer) 128 bits pour des transactions sécurisées sur protocole Internet (IP).',
        industry_leading_encryption_hardware_and_software_methods_to_protect: 'Méthodes matérielles et logicielles de cryptage et protocoles de sécurité de pointe pour protéger les informations des clients.',
        compliance_with_pci_dss: 'Conformité à la norme de sécurité des données de l\'industrie des cartes de paiement (PCI DSS).',
        for_additional_info_regarding_the_privacy_of_your_sensitive_cardholder_data: 'Pour plus d\'informations concernant la confidentialité des données sensibles de votre titulaire de carte, veuillez lire la politique de confidentialité d\'Authorize.Net -',
        bsi_online_is_registered_with_authorize_net: 'BSI Online est enregistré auprès du programme de sceau de marchand vérifié Authorize.net.',
        _devices: " dispositifs",
        _name: "nom",
        _notifications: "avis",
        _properties: "propriétés",
        a_new_role_was_added: "Un nouveau rôle a été ajouté",
        a_test_was_done_on: "Un contrôle a été fait sur",
        account: "Compte",
        account_number: "Numéro de compte",
        actions: "Actions",
        Activate: "Activer",
        active: "Actif",
        Active: "Actif",
        active_only: "Actif uniquement",
        activity: "Activité",
        activity_log: "Journal d’activité",
        add: "Ajouter",
        add_category: "Ajouter une catégorie",
        add_comment: "Ajouter un commentaire",
        add_device: "Ajouter un dispositif",
        add_letter_template: 'Ajouter un modèle de lettre',
        add_new_device: "Ajouter un nouveau dispositif",
        add_new_letter_template: 'Ajouter un nouveau modèle de lettre',
        add_new_property: "Ajouter une nouvelle propriété",
        add_organization_node: "Ajouter un nœud d’organisation",
        add_property: "Ajouter une propriété",
        add_role: "Ajouter un rôle",
        add_template: 'Ajouter un modèle',
        add_user: "Ajouter un utilisateur",
        additional_info: "Renseignements supplémentaires",
        AddProperty: "Ajouter une propriété",
        address: "Adresse",
        address_name: "Nom d’adresse",
        address_two: "Adresse 2",
        AddUser: "Ajouter un utilisateur",
        all: "Tout",
        All_documents_should_be_placed_here: "Tous les documents doivent être placés ici",
        All_the_other_stuff_goes_here: "Tout le reste va ici",
        and_percentage_of_commercial_non_compliant_devices: "et pourcentage de dispositifs commerciaux non conformes",
        and_percentage_of_residential_non_compliant_devices: "et pourcentage de dispositifs résidentiels non conformes",
        attached_files: 'Documents attachés',
        are_you_sure: "Voulez-vous continuer?",
        assemblies: "Assemblages",
        AssetCategories: "Catégories d’actifs",
        assign: "Attribuer",
        assign_new_role: "Attribuer un nouveau rôle",
        assign_user_to_the_role: "Attribuer un utilisateur au rôle",
        batch_progress: 'Progression du lot',
        batch_size: 'Taille du lot',
        batches_processed: 'Lots traités',
        back_to_login: "Retour à la page de connexion",
        Backflow_test_price: "Prix du contrôle du dispositif antirefoulement",
        blocked: "Bloqué",
        by: "par",
        By: 'Par',
        calibration_date: "Date d'étalonnage",
        cancel: "Annuler",
        category: "Catégorie",
        category_add: 'Catégorie ajouter',
        category_delete: 'Suppression de catégorie',
        category_update: 'Mise à jour de la catégorie',
        category_description: "Description de la catégorie",
        category_name: "Nom de la catégorie",
        ccn: "NCC",
        ccn_copied: 'NCC copié!',
        certificate: "Certificat",
        certification_date: "Date de certification",
        change: "Modifier",
        change_contact: "Modifier la personne-ressource",
        change_due_date: "Modifier la date d’échéance",
        change_mailing_address: "Modifier l’adresse postale",
        change_password: "Modifier le mot de passe",
        change_physical_address: "Modifier l’adresse physique",
        Chill_Water: "Refroidir l’eau",
        choose_the_property: "Choisir la propriété",
        city: "Ville",
        City: "Ville",
        clear: "Supprimer",
        clear_search_from: "Supprimer les données de recherche",
        click_edit_to_add_description: "Cliquer sur Modifier pour ajouter une description",
        close: 'Fermer',
        code: 'Code',
        coming_soon: "Bientôt disponible",
        comment: "Commentaire",
        comment_was_successfully_added: "Le commentaire a été ajouté",
        comments: "Commentaires",
        commercial: "Commercial",
        Commercial: "Commercial",
        company: 'Entreprise',
        company_name: "Nom de l’entreprise",
        compliance: "Conformité",
        confirm_password: "Confirmer le mot de passe",
        contact: "Personne-ressource",
        contact_details: "Renseignements sur la personne-ressource",
        contact_info: "Coordonnées",
        contact_information: "Coordonnées",
        contract_expiration_date: "Date de fin du contrat",
        copy_ccn: 'Copier NCC',
        correct_all_values: "corriger toutes les valeurs",
        created_by: 'Créé par',
        create_new_password: "Veuillez créer votre nouveau mot de passe.",
        created_at: 'Créé à',
        created_date: 'Date de création',
        creation_date: "Date de création",
        current_device: "Dispositif actuel",
        current_password: "Mot de passe actuel",
        current_snapshot_report_as_of: "Rapport instantané actuel (au",
        current_to_date: 'Actuel à ce jour',
        CurrentSnapshot: "Instantané actuel",
        custom: "Personnalisé",
        Custom: "Personnalisé",
        custom_reports: "Rapports personnalisés",
        dashboard: "Tableau de bord",
        Dashboard: "Tableau de bord",
        data: "Données",
        date: "Date",
        date_range: "Période",
        date_range_grouping: "Groupage par périodes",
        day: "jour",
        days: "jours",
        days_since_compliant: "Conforme depuis (jours)",
        Deactivate: "Désactiver",
        dedicated_fire_service_connections: "Raccordements réservés aux pompiers",
        dedicated_irrigation_service_connections: "Raccordements réservés au service d’irrigation",
        default: "Défaut",
        delete: "Supprimer",
        delete_role: "Supprimer le rôle",
        DEP: "DPE",
        dep: "DPE",
        department_of_environmental_protection_report: "Rapport du département de la Protection de l’environnement",
        department_of_public_health_report: "Rapport du département de la Santé publique",
        Describes_the_cost_of_a_backflow_test_: "Décrit le coût d’un contrôle du dispositif antirefoulement",
        description: "Description",
        details: "Renseignements",
        Details: "Renseignements",
        device: "Dispositif",
        device_activate: "Le dispositif a été activé",
        device_deactivate: "Le dispositif a été désactivé",
        device_due_date_was_changed_successfully: "La date d’échéance du dispositif a été modifiée",
        device_meter_number: "Numéro de compteur du dispositif",
        device_move: "Déplacement du dispositif",
        device_replace: "Remplacement du dispositif",
        device_serial_number: "Numéro de série du dispositif",
        device_status: "État du dispositif",
        device_timeline: "Chronologie du dispositif",
        device_update: "Mise à jour du dispositif",
        device_was_created: "Le dispositif a été créé",
        device_was_moved_successfully: "Le dispositif a été déplacé",
        device_was_replaced_successfully: "Le dispositif a été remplacé ",
        device_was_successfully: "Le dispositif a été",
        device_was_updated_successfully: "Le dispositif a été mis à jour",
        DeviceDetails: "Renseignements sur le dispositif",
        devices: "Dispositifs",
        devices_due: "Dispositifs échus",
        devices_by_due_date: 'Dispositifs par date d\'échéance',
        devices_las_tested: "Dispositifs les plus récemment contrôlés",
        devices_last_tested: "Dispositifs les plus récemment contrôlés",
        devices_tested: "Dispositifs contrôlés",
        devices_with_no_previous_data: "Dispositifs sans données antérieures",
        DevicesByDueDate: 'Dispositifs par date d\'échéance',
        DevicesLastTested: "Dispositifs les plus récemment contrôlés",
        Documents: "Documents",
        Domestic: "Domestique",
        DPH: "DSP",
        dph: "DSP",
        drag_and_drop: "Glisser-déposer",
        due_date: "Date d’échéance",
        due_date_change: "Modification de la date d’échéance",
        due_date_is_incorrect: "La date d’échéance est incorrecte",
        due_date_of_selected_devices: 'Date d\'échéance des dispositifs sélectionnés',
        due_date_was_set_to: "La date d’échéance a été fixée au",
        edit: "Modifier",
        edit_category: "Modifier la catégorie",
        edit_contact_details: "Modifier les renseignements sur la personne-ressource",
        edit_device: "Modifier le dispositif",
        edit_mailing_address: "Modifier l’adresse postale",
        edit_media: "Modifier le contenu multimédia",
        edit_organization_node: "Modifier le nœud d’organisation",
        edit_physical_address: "Modifier l’adresse physique",
        edit_property_details: "Modifier les renseignements sur la propriété",
        edit_role: "Modifier le rôle",
        EditProperty: "Modifier la propriété",
        EditUser: "Modifier l’utilisateur",
        edit_user: "Modifier l’utilisateur",
        email: "Adresse courriel",
        email_was_sent: "Un courriel a été envoyé",
        environmental_protection_agency_report_as_of: "Rapport de l’Environmental Protection Agency (au",
        EPA: "EPA",
        error: "Erreur",
        existing: "Existant",
        exit: "Quitter",
        expiration: "Expiration",
        expiration_date: "Date d’échéance",
        export_as_csv: "Exporter au format CSV",
        export_licenses: "Exporter les licences",
        export_test_kits: "Exporter des kits de test",
        export_testers: "Exporter les contrôleurs",
        export_tests: "Exporter les contrôles",
        extension_date: "Date de prolongation",
        extension_date_is_incorrect: "La date de prolongation est incorrecte",
        extension_was_granted_set_to: "La prolongation a été accordée. Mis au",
        extension_was_granted_successfully: "La prolongation a été accordée",
        fail: "Échec",
        failed: "Échoué",
        Failed: "Échoué",
        fax: "Télécopieur",
        file: 'File',
        filters: "Filtres",
        finished: 'Fini',
        Fire_Bypass: "Dérivation de raccordement pour le Service des incendies",
        "Fire Protection": "Protection contre les incendies",
        "Fire Bypass": "Dérivation de raccordement pour le Service des incendies",
        Fire_Protection: "Protection contre les incendies",
        first_name: "Prénom",
        first_name_may_only_contain_alphabetic_characters: "Le prénom ne peut contenir que des caractères alphabétiques",
        forecast: "Prévisions",
        Forecast: "Prévisions",
        forecast_details: "Renseignements sur les prévisions",
        ForecastReport: "Rapport de prévisions",
        forgot_password: "Mot de passe oublié?",
        french: 'Français',
        from: "de",
        from_date: "À partir de ",
        from_the_current_property: "De la propriété actuelle",
        full_name: "Nom complet",
        general: "Généralités",
        generation_date: 'Date de génération',
        generate_letter: "Produire des lettres",
        generate_letters: "Produire des lettres",
        generated: 'Généré',
        generated_at: 'Généré à',
        generated_date: 'Date de génération',
        grant: "Accorder",
        grant_extension: "Accorder le prolongement",
        grant_extension_date: "Date de l’octroi du prolongement",
        growth: "Croissance",
        Growth: "Croissance",
        growth_details: "Renseignements sur la croissance",
        GrowthReport: "Rapport de croissance",
        grouping_strategy: 'Stratégie de regroupement',
        hazard: "Danger",
        Hazard: "Danger",
        hazard_type: "Type de danger",
        help_desk: "Bureau d’aide",
        HelpDesk: "Bureau d’aide",
        hide: "Cacher",
        imperial: "impérial",
        impersonating: "Emprunt du compte",
        in_progress: 'En cours',
        in_this_case: "Dans ce cas",
        inactive: "Inactif",
        Inactive: "Inactif",
        Inactive_Irrigation_Forms: 'Formulaires d\'irrigation inactifs',
        info: "Info",
        initial_date: "Date initiale",
        initial_due_date: "Date d’échéance initiale",
        insert: 'Insérer',
        inspections: "inspections",
        Inspections: "Inspections",
        Inspection_Forms: 'Formulaires d\'inspection',
        installs: 'Installations',
        install_date: "Date d’installation",
        Irrigation: "Irrigation",
        Irrigation_shut_off_forms: "Formes d’arrêt d’irrigation",
        is_this_a_residence: "Est-ce une résidence",
        isolation_containment: "Isolation/Confinement",
        label: 'Label',
        language: 'Langue',
        last_test_dte: 'Date du dernier test',
        last_12_months: "12 derniers mois",
        last_30_days: "30 derniers jours",
        last_7_days: "7 derniers jours",
        last_login_at: "Dernière connexion à",
        last_login_from: "Dernière connexion depuis",
        last_month: "Mois dernier",
        last_name: "Nom de famille",
        last_name_may_only_contain_alphabetic_characters: "Le nom de famille ne peut contenir que des caractères alphabétiques",
        last_notification: 'Dernière notification',
        last_notification_sent: 'Dernière notification envoyée',
        last_quarter: "Trimestre dernier",
        last_test_info: "Renseignements sur le dernier contrôle",
        last_test_result: "Résultat du dernier contrôle",
        last_test_status: 'État du dernier test',
        last_tested: "Dernier contrôle",
        last_year: "Année dernière",
        letter: "Lettre",
        letter_header: 'En-tête de lettre',
        letter_template_addition: 'Ajout de modèle de lettre',
        letter_template_details: 'Détails du modèle de lettre',
        letter_template_updated: 'Le modèle de lettre a été mis à jour',
        letter_template_was_successfully_updated: 'Le modèle de lettre a été mis à jour avec succès',
        letter_type: 'Type de lettre',
        letters: "lettres",
        letters_page: "Page des lettres",
        license_is_due_in_less_than: "La licence expire dans moins de ",
        license_is_past_due: "La licence est échue",
        license_is_valid: "La licence est valide",
        license_number: "Numéro de licence",
        list_properties_for_template: 'Propriétés de la liste pour le modèle',
        location: "Emplacement",
        logging_in: "Connexion...",
        login: "Ouvrir une session",
        Logout: "Se déconnecter",
        Mail_Surveys: 'Sondages par courrier',
        mailing_address: "Adresse postale",
        mandatory: "obligatoire",
        manufacturer: "Fabricant",
        Manufacturer: "Fabricant",
        measure_system: "Système de mesure",
        media: "Contenu multimédia",
        media_was_added: "Le contenu multimédia a été ajouté",
        media_delete: "Suppression de média",
        media_update: "Mise à jour des médias",
        media_upload: 'Téléverser des contenus multimédias',
        meter: "Compteur",
        meter_number: "Numéro du compteur",
        mm_dd_yyyy: "mm/jj/aaaa",
        mn: "NC",
        model: "Modèle",
        Model: "Modèle",
        month: "mois",
        Month: "Mois",
        move: "Déplacer",
        move_device: "Déplacer le dispositif",
        move_here: "Déplacer ici",
        MoveDevice: "Déplacer le dispositif",
        n_a: "s.o.",
        name: "Nom",
        need_to_search_for_letters_sent_to_your_properties: "Besoin de chercher des lettres envoyées à vos propriétés",
        new_comment: "Nouveau commentaire",
        new_date: "Nouvelle date",
        new_device: "Nouveau dispositif",
        New_device: "Nouveau dispositif",
        new_device_was_added_successfully: "Le nouveau dispositif a été ajouté",
        new_devices: "Nouveaux dispositifs",
        new_installs: "Nouvelles installations",
        new_installs_and_replacements: 'Nouvelles Installations et Remplacements',
        new_category_was_added_successfully: 'La nouvelle catégorie a été ajoutée avec succès',
        new_letter_template_was_added_successfully: 'Le nouveau modèle de lettre a été ajouté avec succès',
        new_permission_was_successfully_added: "La nouvelle autorisation a été ajoutée",
        new_property_added: "Nouvelle propriété ajoutée",
        new_property_was_added_successfully: "La nouvelle propriété a été ajoutée",
        next: 'Suivant',
        no: "Non",
        NO: "NON",
        no_assets_in_this_media_category: 'Aucun élément dans cette catégorie Médias',
        no_data: "aucune donnée",
        no_data_about_test_results: "aucune donnée sur les résultats des contrôles",
        no_data_about_testing_company: "aucune donnée sur l’entreprise chargée des contrôles",
        no_file: 'Pas de fichier',
        no_media_assets_associated_with_this_property: 'Aucun élément multimédia associé à cette propriété',
        no_notifications: "Aucun avis",
        no_results: "Aucun résultat",
        node_name: "Nom du nœud",
        Non_compliance_Date_Range: "Période de non-conformité",
        non_compliant_devices: 'dispositifs non conformes',
        non_compliance_report: "Rapport de non-conformité",
        non_residential: "Non résidentiel",
        NonCompliant: "Non conforme",
        not_authorized: "Non autorisé",
        notes: 'Record',
        notification: 'Notification',
        notifications: "Avis",
        notifications_tested: "avis contrôlés",
        notify_bsi_via_email_of_your_comment: "Aviser BSI par courriel de la soumission d’un commentaire",
        now_to_end_of_year: "De maintenant à la fin de l’année",
        number: "Nombre",
        number_of_active_and_inactive_devices: "Nombre de dispositifs actifs et inactifs",
        number_of_devices_added_to_system: "Nombre de dispositifs ajoutés au système",
        number_of_devices_by_due_date: 'Nombre de dispositifs par date d\'échéance',
        number_of_devices_due: "Nombre de dispositifs dus",
        number_of_devices_that_have_most_recent_test_performed_in_the_selected_period: "Nombre de dispositifs dont le plus récent contrôle a été effectué au cours de la période sélectionnée",
        number_of_testing_notification_forms_sent_for_the_month_of_: "Nombre de formulaires d’avis de contrôle envoyés pour le mois de ",
        number_of_valid_test_forms_received_from: "Nombre de formulaires de contrôle valides reçus de ",
        of: "de",
        OldestFirst: "Plus ancien en premier",
        OldestLast: "Plus ancien en dernier",
        order_by: "Ordonner selon",
        Org_Admin: "Administrateur de l’organisation",
        org_structure: "Structure de l’organisation",
        Org_Temp: "Organisation temporaire",
        Org_User: "Utilisateur de l’organisation",
        Organization: "Organisation",
        Organization_Administrator: "Administrateur de l’organisation",
        organization_hierarchy: "Hiérarchie de l’organisation",
        organization_information: "Renseignements sur l’organisation",
        organization_settings: "Paramètres de l’organisation",
        organization_structure: "Structure de l’organisation",
        Organization_Temporary: "Organisation temporaire",
        Organization_User: "Utilisateur de l’organisation",
        OrganizationDetails: "Renseignements sur l’organisation",
        OrganizationSettings: "Paramètres de l’organisation",
        OrganizationStructure: "Structure de l’organisation",
        Other: "Autre",
        Pass: "Réussi",
        passed: "Réussi",
        password: "Mot de passe",
        password_changed: "Votre mot de passe à été changé avec succès!",
        password_confirmation: "Confirmation du mot de passe",
        password_is_required: "Mot de passe requis",
        past_due: "Échus",
        past_due_date: 'Date d\'échéance dépassée',
        past_due_devices: "Dispositifs échus",
        per_page: 'Par page',
        percentage_of: "Pourcentage de",
        percentage_of_devices_ordered_to_be_tested_and_actually_tested_since_january: "Pourcentage de dispositifs visés par une demande de contrôle et réellement contrôlés depuis Janvier",
        percentage_of_ordered_tests_now_in_violation_since: "Pourcentage de contrôles demandés maintenant en violation depuis",
        percentage_of_total_non_compliant_devices: "Pourcentage de dispositifs non conformes",
        permission_added: "Autorisation ajoutée",
        permission_revoked: "Autorisation révoquée",
        permission_was_successfully_revoked: "L’autorisation a été révoquée",
        permissions: "Autorisations",
        phone: "Téléphone",
        physical_address: "Adresse physique",
        Pictures: "Images",
        Pictures_folder: "Dossier d’images",
        please_check_your_email: "Veuillez vérifier votre messagerie et suivre les directives pour récupérer votre mot de passe.",
        please_confirm_you_want_to_move_the_selected_device: "Veuillez confirmer que vous souhaitez déplacer le dispositif sélectionné",
        please_contact_your_administrator: "Veuillez communiquer avec votre administrateur",
        please_correct_the_form_errors: "Veuillez corriger les erreurs dans le formulaire",
        please_enter_valid_email: "Veuillez saisir une adresse courriel valide",
        possible_duplicate_physical_address_was_found: "Une adresse physique en double a été trouvée! Veuillez voir ci-dessous.",
        post: "Publier",
        post_comment: "Publier un commentaire",
        postal_code: "Code postal",
        potable: "Potable",
        preference_settings: 'Paramètres de préférence',
        premise: "Lieux",
        premise_number: 'Numéro de local',
        present_to_year_end: "De maintenant à la fin de l’année",
        print: 'Impression',
        previous: 'Précédent',
        quick_print: 'Impression rapide',
        process_queue: 'File d\'attente de processus',
        Process_Water: "Traiter l’eau",
        processed: 'Traité',
        program_name: 'Nom du programme',
        Profile: "Profil",
        profile_settings: "Paramètres du profil",
        properties: "Propriétés",
        properties_and: "propriétés et",
        properties_tested: "Propriétés contrôlées",
        properties_with_possible_duplicate_address: 'Propriétés avec adresse en double possible',
        property: "Propriété",
        Property: "Propriété",
        property_activate: "La propriété a été activée",
        property_address_update: "Mise à jour de l’adresse de la propriété",
        Property_assets: "Actifs immobiliers",
        property_contact_details_were_updated_successfully: "Les coordonnées de la propriété ont été mises à jour",
        property_contact_update: "Mise à jour des coordonnées de la propriété",
        property_deactivate: "La propriété a été désactivée",
        property_details: "Renseignements sur la propriété",
        property_information: "Renseignements sur la propriété",
        property_mailing_address_was_updated_successfully: "L’adresse postale de la propriété a été mise à jour",
        property_name: "Nom de la propriété",
        property_physical_address_was_updated_successfully: "L’adresse physique de la propriété a été mise à jour",
        property_status: "État de la propriété",
        property_type: "Type de propriété",
        property_update: "Mise à jour de la propriété",
        property_was_updated_successfully: "La propriété a été mise à jour",
        PropertyDetails: "Renseignements sur la propriété",
        proportion_of_devices_that_are_current_to_date_and_past_due_date: "Proportion de dispositifs à jour et en retardactifs et échus",
        provide_username: "Veuillez indiquer votre nom d’utilisateur pour récupérer le mot de passe.",
        province: "Province",
        pwsid: "PWSID",
        quarter: "trimestre",
        queue: 'File d\'attente',
        queue_details: 'Détails de la file d\'attente',
        queue_name: 'Nom de la file d\'attente',
        queued: 'En file d\'attente',
        queue_number: 'Numéro de file d\'attente',
        quick_select: "Sélection rapide",
        reason: "Raison",
        receive_non_compliant_emails: "Recevoir les courriels sur les non-conformités",
        receiving_reports: "en réception de rapports",
        records_to_show: "Enregistrements à montrer",
        remember_me: "Mémoriser l’utilisateur",
        remove_selected: 'Enlever la sélection',
        remove_all: 'Enlever tout',
        repaired: "Réparé",
        replace: "Remplacer",
        replacements: 'Remplacements',
        replace_device: "Remplacer le dispositif",
        replaced: "Remplacé",
        report: 'Rapport',
        Reports: "Rapports",
        required_field: "Champ obligatoire!",
        reset: "Réinitialiser",
        reset_password: "Réinitialiser le mot de passe",
        reset_tour: 'Réinitialiser tour',
        residential: "Résidentiel",
        Residential: "Résidentiel",
        result: "Résultat",
        results: "Résultats",
        revoke: "Révoquer",
        role_added: "Rôle ajouté",
        role_deleted: "Rôle supprimé",
        role_description: "Description du rôle",
        "role from": 'le rôle de',
        role_name: "Nom du rôle",
        role_updated: "Rôle mis à jour",
        role_was_added: "Le rôle a été ajouté",
        role_was_removed: "Le rôle a été supprimé",
        roles: "Rôles",
        Roles: "Rôles",
        rsg: "GRS",
        same_as_physical: "Même que l’adresse physique",
        save: "Enregistrer",
        save_current_report: "Enregistrer le rapport actuel",
        saved_reports: "Rapports enregistrés",
        search: "Rechercher",
        SearchLetters: 'Lettres de recherche',
        search_by_device: "Recherche par dispositif",
        search_by_keyword: "Recherche par mots-clés",
        search_by_property: "Recherche par propriété",
        search_by_serial_or_meter_number: "Recherche par numéro de série ou de compteur",
        search_by_serial_meter_or_premise_number: "Recherche par numéro de série, de compteur ou de site",
        search_devices: "Dispositifs de recherche",
        search_for_a_property_to_move_the_device_to: "Rechercher une propriété vers laquelle déplacer le dispositif",
        search_for_devices: "Recherche des dispositifs",
        search_for_properties: "Rechercher des propriétés",
        search_for_testers: 'Rechercher des testeurs',
        search_by_address_name_or_ccn: "Rechercher des propriétés par nom d’adresse ou NCC",
        search_for_test_kits: "Rechercher des kits de test",
        search_for_testing_companies: "Rechercher des entreprises chargées des contrôles",
        search_or_start_a_new_chat: "Rechercher une conversation ou en démarrer une nouvelle",
        search_properties: "Propriétés de recherche",
        search_queues: 'files d\'attente de recherche',
        search_records: "Rechercher des enregistrements",
        search_testers: "Rechercher des contrôleurs",
        search_users: "Rechercher des utilisateurs",
        searching: "Recherche en cours…",
        select_all: 'Tout sélectionner',
        selected: 'Choisi',
        selected_device: "Dispositif sélectionné",
        selected_devices: 'Dispositifs sélectionnés',
        send: "Envoyer",
        sender: 'Envoyeur',
        sent: 'Envoyé',
        sent_letters_report: "Rapport sur les lettres envoyées",
        serial: "Série",
        serial_number: "Numéro de série",
        service_connections: "Raccordements",
        settings: "Paramètres",
        show: "Montrer",
        size: "Taille",
        Size: "Taille",
        skip: 'Sauter',
        sn: "NS",
        some_context_here: "Un peu de contexte ici",
        sorry_but_no_results_were_found: "Désolé, aucun résultat n’a été trouvé",
        sorry_but_you_have_no_permission_to_see_this_page: "Désolé, vous n’avez pas l’autorisation de voir cette page",
        sorry_no_matching_options: "Désolé, aucune option correspondante",
        special_license: "Licence spéciale",
        standard_service_connections: "Raccordements standard",
        start_typing_here: "Commencer à taper ici...",
        state: "État",
        statistics: "Statistiques",
        State: "État",
        status: 'Statut',
        Status: 'Statut',
        Structure: "Structure",
        success: "Réussite",
        switch_to_bsi_20: "Basculer vers BSI 2.0",
        take_a_tour: 'Montrer le tour',
        template: "Modèle",
        template_deletion: 'Suppression du modèle',
        template_name: 'Nom du modèle',
        template_was_deleted_successfully: 'Le modèle a été supprimé avec succès',
        Test_Forms_prior_to_BSI_: 'Formulaires de test avant BSI',
        test_kit: "Kit de test",
        test_kits: "Kits de test",
        test_notifications: "Avis de contrôle",
        test_report: 'Rapport d\'essai',
        test_result: "Résultat du contrôle",
        test_result_is: "Le résultat du contrôle est",
        test_was_done: "Un contrôle a été fait",
        tested: "Contrôlé",
        tested_by: "Contrôleur",
        tested_date: "Date du contrôle",
        tested_devices_details: "Renseignements sur les dispositifs contrôlés",
        TestedReport: "Rapport sur les contrôles",
        tester: "Contrôleur",
        testers: "Contrôleurs",
        tester_company: "Entreprise du contrôleur",
        tester_name: "Nom du contrôleur",
        testing_companies: "Entreprises chargées des contrôles",
        "Testing Companies": "Entreprises chargées des contrôles",
        testing_companies_report: "Rapport des entreprises chargées des contrôles",
        testing_company: "Entreprise chargées des contrôles",
        testing_licenses: "Licences de contrôle",
        TestingCompanies: "Entreprises chargées des contrôles",
        TestingCompanyDetails: "Renseignements sur l’entreprise chargée des contrôles",
        tests: "Contrôles",
        tests_performed: "Contrôles effectués",
        the_address_name_field_is_required: "Le champ Nom de l’adresse est obligatoire",
        the_category_was_deleted_successfully: 'La catégorie a été supprimée avec succès',
        the_category_was_updated_successfully: 'La catégorie a été mise à jour avec succès',
        the_email_field_is_required: "Le champ Courriel est obligatoire",
        the_first_name_field_is_required: "Le champ Prénom est obligatoire",
        the_last_name_field_is_required: "Le champ Nom est obligatoire",
        the_media_was_successfully_added_to_the_property: "Le contenu multimédia a été ajouté à la propriété",
        the_media_was_successfully_deleted_from_the_property: "Le contenu multimédia a été supprimé de la propriété",
        the_media_was_updated_successfully: "Le contenu multimédia a été mis à jour",
        The_number_of_days_past_a_device_s_due_date_before_the_device_becomes_non_compliant: "Le nombre de jours après la date d’échéance d’un dispositif avant que celui-ci devienne non conforme",
        the_password_confirmation_field_is_required: "Le champ Confirmer le mot de passe est obligatoire",
        the_password_field_is_required: "Le champ Mot de passe est obligatoire",
        the_role_was_added: "Le rôle a été ajouté",
        the_role_was_added_to_the_user: "Le rôle a été ajouté à l’utilisateur",
        the_role_was_deleted: "Le rôle a été supprimé",
        the_role_was_revoked_from_the_user: "Le rôle a été révoqué pour cet utilisateur",
        the_test_was_paid: "Le contrôle a été payé",
        the_zip_postal_code_field_is_required: "Le champ Code postal est obligatoire",
        these_are_the_testing_companies_that_have_done_work_in_your_organization: "Ce sont les entreprises chargées des contrôles qui ont travaillé dans votre organisation",
        this_data_is_exclusively_based_on_the_most_recent_test_result: 'Ces données sont exclusivement basées sur le résultat du test le plus récent',
        this_field_may_only_contain_alphabetic_characters: "Ce champ ne peut contenir que des caractères alphabétiques",
        this_month: "Ce mois",
        this_percentage_is_acquired_by_dividing_non_compliant_devices_by_the_total_number_of_devices: "Ce pourcentage est obtenu en divisant le nombre de dispositifs non conformes par le nombre total de dispositifs",
        this_quarter: "Ce trimestre",
        this_report_shows_you_all_of_the_transactions_in_the_system: "Ce rapport montre toutes les transactions du système",
        this_week: "Cette semaine",
        this_year: "Cette année",
        timeline: "Chronologie",
        to: "à",
        to_date: "À ce jour",
        to_this_property: "À cette propriété",
        total: "Total",
        total_letters: 'Nombre total de lettres',
        total_non_residential_service_connections: "Total des raccordements non résidentiels",
        total_residential_service_connections: 'Total des raccordements résidentiels',
        total_number_of_backflow_device_records_in_database: "Nombre total de dispositifs antirefoulement dans la base de données",
        total_number_of_backflow_prevention_devices_scheduled_for_annual_testing: "Nombre total de dispositifs antirefoulement visés par un contrôle annuel prévu",
        total_number_of_devices_ordered_to_be_tested_since_january: "Nombre total de dispositifs visés par une demande de contrôle depuis Janvier",
        total_number_of_devices_tested_since_january: "Nombre total de dispositifs contrôlés depuis Janvier",
        total_number_of_notifications_awaiting_return_of_test_forms: "Nombre total d’avis pour lesquels un formulaire de contrôle n’a pas été retourné",
        total_tests: "Nombre total de contrôles",
        transactional_report: "Rapport transactionnel",
        type: "Type",
        type_the_name_of_the_report_here: "Saisir le nom du rapport ici",
        update: "Mettre à jour",
        update_profile: "Mettre à jour le profil",
        upload_media: "Téléverser des contenus multimédias",
        media_is_required: 'multimédias est obligatoire.',
        upload_picture: "Téléverser une photo",
        use_this_to_generate_your_own_reports_and_save_the_criteria_to_use_again_later: "À utiliser pour produire des rapports personnalisés et enregistrer des critères",
        user_roles: "Rôles de l’utilisateur",
        UserActivity: "Activité de l’utilisateur",
        username: "Nom d’utilisateur",
        username_is_required: "Le nom d’utilisateur est obligatoire.",
        users: "Utilisateurs",
        Users: "Utilisateurs",
        users_hierarchy: "Hiérarchie des utilisateurs",
        users_with_the_role: "Utilisateurs ayant le rôle",
        users_without_the_role: "Utilisateurs n’ayant pas le rôle",
        vetted: "Approuvé",
        view_details: "Détails",
        view_edit_letter_template_details: 'Afficher, modifier les détails du modèle de lettre',
        view_timeline: "Afficher la chronologie",
        we_sent_email: "Nous avons envoyé un courriel de directives à l’adresse associée.",
        week: "semaine",
        weeks: "semaines",
        welcome: "Rebienvenue. Veuillez vous connecter à votre compte.",
        yes: "Oui",
        YES: 'OUI',
        yes_deactivate: "Oui, désactivez !",
        yes_deactivate_it: "Oui, désactivez !",
        yes_delete_it: "Oui, supprimez-le!",
        yes_remove_it: "Oui, supprimez-le !",
        yes_reset_it: 'Oui, réinitialisez-le !',
        yes_revoke_it: "Oui, révoquez-le !",
        you_are_about_to_deactivate_this_device: "Vous êtes sur le point de désactiver ce dispositif!",
        you_are_about_to_deactivate_this_property_and_all_the_devices_associated_with_it: "Vous êtes sur le point de désactiver cette propriété et tous les dispositifs qui lui sont associés!",
        you_are_about_to_deactivate_user: "Vous êtes sur le point de désactiver l’utilisateur!",
        you_are_about_to_delete_media: "Vous êtes sur le point de supprimer le contenu multimédia!",
        you_are_about_to_delete_the_media: "Vous êtes sur le point de supprimer le contenu multimédia!",
        you_are_about_to_delete_the_role: "Vous êtes sur le point de supprimer le rôle!",
        you_are_about_to_delete_this_category: 'Vous êtes sur le point de supprimer cette catégorie',
        you_are_about_to_remove_node_from_organization_tree: "Vous êtes sur le point de supprimer le nœud de l’arborescence de l’organisation",
        you_are_about_to_reset_tour: 'Vous êtes sur le point de réinitialiser la visite',
        you_are_about_to_revoke_the_role: "Vous êtes sur le point de révoquer le rôle!",
        zip: "Code postal",
        zip_code: "Code postal",
        January: "Janvier",
        February: "Février",
        March: "Mars",
        April: "Avril",
        May: "Mai",
        June: "Juin",
        July: "Juillet",
        August: "Août",
        September: "Septembre",
        October: "Octobre",
        November: "Novembre",
        December: "Décembre",
        device_modified: 'Dispositif Modifié',
        "required_field!": "champ obligatoire!",
        "Letters": "Lettres",
        "Templates": "Modèles",
        "Queues": "Files",
        "Testers": "Testeurs",
        search_letters: 'Lettres de recherche',
        "Search Letters": 'Lettres de recherche',
        "State Standards": "Normes de l’État",
        "System": "Système",
        "Letter includes device with hazard": "La lettre comprend un dispositif présentant un danger",
    }

}

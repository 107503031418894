import themeConfig from '../../../themeConfig.js'

const initialState = {
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    userRole: null,
    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar component
    windowWidth: 99999,
    faction: null
}

export const checkPermissions = (menu_item, permissions, debug=false) => {
    if (!permissions) {
        return false
    }
    if (debug) {
        console.log("DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD")
        console.log(menu_item)
        console.log(permissions)
        console.log("DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD")
    }
    if (menu_item.hasOwnProperty('url') || menu_item.hasOwnProperty('permissions')) {
        let permissions_ok
        if (menu_item.permissions === true) {
            permissions_ok = true
        } else {
            let check_perms = () => {
                return menu_item.permissions.some(p => permissions.includes(p))
            }
            permissions_ok = check_perms()
        }

        return permissions_ok
    }
    else {
        return true
    }
}
export const buildSubMenu = (menu_items, permissions, debug=false) => {
    let by_slug = {}
    let sidebar_items = []
    menu_items.forEach(mi => {
        let permitted =  checkPermissions(mi, permissions, debug)
        if (!permitted && mi.hide) {
            return;
        }

        let new_menu_item = {
            ...mi,
            permitted: permitted,
            submenu: null
        }

        if (permitted) {
            by_slug[mi.slug] = {...new_menu_item}
        }
        if (mi.hasOwnProperty('submenu') && mi.submenu.length > 0) {
            let submenu = buildSubMenu(mi.submenu, permissions, debug)
            if (submenu) {
                new_menu_item.submenu = submenu.sidebar_items
                by_slug = {...by_slug,
                           ...submenu.by_slug}
            }
        }
        sidebar_items.push(new_menu_item)

    })
    return {sidebar_items: sidebar_items, by_slug: by_slug}
}
export const sanitize_slugs = (sidebarItems) => {
    let _sidebarBySlug = {}
    const _findSlugs = (menu_items) => {
        menu_items.forEach(m => {
            if (m.hasOwnProperty('submenu')) {
                _findSlugs(m.submenu)
            }

            if (_sidebarBySlug.hasOwnProperty(m.slug)) {
                throw new Error("slug cannot be repeated in sidebarItems!" + m.slug)
            }
            let {submenu: _, ...rest} = m;

            if (rest.hasOwnProperty('url')) {
                if (!rest.hasOwnProperty('permissions')) {
                    throw new Error("All sidebar items require a permission key, if always allowed please use `permissions: true`: " + m.slug)
                }

                _sidebarBySlug[m.slug] = {...rest}
            }
        })
    }
    _findSlugs(sidebarItems)
    return _sidebarBySlug
}

export const sidebar = {
    namespaced: true,
    actions: {
        updateSidebarWidth({commit}, v) {
            commit('UPDATE_SIDEBAR_WIDTH', v)
        },
        toggleIsSidebarActive({state, commit}) {
            commit('TOGGLE_IS_SIDEBAR_ACTIVE', !state.isSidebarActive)
        },
        setIsSidebarActive({commit}, v) {
            commit('TOGGLE_IS_SIDEBAR_ACTIVE', v)
        },
        toggleReduceButton({state, commit}) {
            commit('TOGGLE_REDUCE_BUTTON', !state.reduceButton)
        },
        setReduceButton({commit}, v) {
            commit('TOGGLE_REDUCE_BUTTON', v)
        },
        updateI18nLocale({commit}, locale) {
            commit('UPDATE_I18N_LOCALE', locale)
        },
        setContentOverlay({commit}, v) {
            commit('TOGGLE_CONTENT_OVERLAY', v)
        },
        updateTheme({commit}, val) {
            commit('UPDATE_THEME', val)
        },
        updateUserRole({commit}, val) {
            commit('UPDATE_USER_ROLE', val)
        },
        updateWindowWidth({commit}, width) {
            commit('UPDATE_WINDOW_WIDTH', width)
        },
        updatePrimaryColor({commit}, v) {
            commit('UPDATE_PRIMARY_COLOR', v)
        },
    },
    state: initialState,
    watch: {
        '$faction'(v) {
            this.$store.state.sidebar.faction = v
        }
    },
    mutations: {
        UPDATE_SIDEBAR_WIDTH(state, width) {
            state.sidebarWidth = width;
        },
        UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
            state.sidebarItemsMin = val;
        },
        TOGGLE_REDUCE_BUTTON(state, val) {
            state.reduceButton = val;
        },
        TOGGLE_CONTENT_OVERLAY(state, val) {
            state.bodyOverlay = val;
        },
        TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
            state.isSidebarActive = value;
        },
        UPDATE_THEME(state, val) {
            state.theme = val;
        },
        UPDATE_WINDOW_BREAKPOINT(state, val) {
            // state.breakpoint = val;
        },
        UPDATE_USER_ROLE(state, val) {
            state.userRole = val
            localStorage.setItem('userRole', val)
        },
        UPDATE_WINDOW_WIDTH(state, width) {
            state.windowWidth = width;
        },
    }
};

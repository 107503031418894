import config from "@/config"
import moment from 'moment-timezone'
import {localstorage} from "./LocalStorage"

export const utility = {
    encodeToDate,
    setDisplayTimeZone,
    colorizeExpirationDate,
    expirationDateToolTip,
    expirationDateBucket,
    userPermittedForFaction,
    userAuthenticated,
    getUserRedirectUrl,
    getRealUserRedirect,
    impersonateUser,
    checkFaction,
    getLoginRoute,
    getBsi20Url,
    getImpersonatedUser,
    stopImpersonating,
    isImpersonating
}

function encodeToDate(date) {
    return moment(date).format('YYYY-MM-DD')
}

function setDisplayTimeZone(date) {
    return moment(date.substring(0,10)).format('l')
}


function expirationDateBucket(expDate) {
    let day_gap = moment(expDate).diff(moment(), 'days');
    // let day_gap = 91;

    if( day_gap <= 0 ) // past due
    {
        return 'expired';
    }
    if( day_gap > 0 && day_gap <= 30 ) // 30 day
    {
        return '30_day';
    }
    else if( day_gap > 30 && day_gap <= 60 ) // 60 day
    {
        return '60_day';
    }
    else if( day_gap > 60 && day_gap <= 90 ) // 90 day
    {
        return '90_date';
    }
    else // ok
    {
        return 'active';
    }
}

function colorizeExpirationDate(expDate) {
    let bucket = expirationDateBucket(expDate)
    switch(bucket) {
        case 'expired':
            return 'danger';
        case '30_day':
            return 'warning';
        case '60_day':
            return 'secondary';
        case '90_date':
            return 'primary';
        case 'active':
            return 'success';
    }
}

import i18n from '../shared/i18n/i18n'
import conf from "../config";
function expirationDateToolTip(expDate) {
    let bucket = expirationDateBucket(expDate)
    switch(bucket) {
        case 'expired':
            return i18n.t('license_is_past_due')
        case '30_day':
            return i18n.t('license_is_due_in_less_than') + '30 ' + i18n.t('days')
        case '60_day':
            return i18n.t('license_is_due_in_less_than') + '60 ' + i18n.t('days');
        case '90_date':
            return i18n.t('license_is_due_in_less_than') + '90 ' + i18n.t('days');
        case 'active':
            return i18n.t('license_is_valid');
    }
}

const LOGIN_ROUTE = '/app/login'
function getLoginRoute() {
    return LOGIN_ROUTE
}

const FACTIONS = [
    'bsi',
    'org',
    'testing-company'
]
function checkFaction(faction) {
    if(!FACTIONS.includes(faction)) {
        throw "Invalid faction! " + faction
    }
}

function getRealUser() {
    return localstorage.getUser()
}
function getRealUserRedirect() {
    return getRealUser().redirect_url
}

function userAuthenticated() {
    return getRealUser() !== null
}

function getImpersonatedUser() {
    return localstorage.getImpersonatedUser()
}

function getUser() {
    return getImpersonatedUser() || getRealUser()
}

function getUserRedirectUrl() {
    let impersonated = getImpersonatedUser()
    if (impersonated) {
        return impersonated.redirect_url || LOGIN_ROUTE
    }
    let user = getUser()
    if (user) {
        return user.redirect_url || LOGIN_ROUTE
    }
    return LOGIN_ROUTE
}

function getUserFaction() {
    return getUser().faction
}
function userPermittedForFaction(faction) {
    return faction === getUserFaction()
}

function safeLocationReplace(redirect_url) {
    location.replace( redirect_url.startsWith('http') ?
        redirect_url :
        '/' + redirect_url)
}
function impersonateUser(username, origin_url, redirect_url, faction) {
    localStorage.setItem(config.IMPERSONATE_USER_KEY,
        JSON.stringify({
            'username': username,
            'origin_url': origin_url,
            'redirect_url': redirect_url,
            'faction': faction,
        })
    )
    safeLocationReplace(redirect_url)
}

function stopImpersonating() {
    let u = getImpersonatedUser()
    localStorage.removeItem(conf.IMPERSONATE_USER_KEY)
    safeLocationReplace(u.origin_url)
}

function isImpersonating() {
    return getImpersonatedUser() !== null
}

function getToken() {
    return getRealUser().token
}
function getLegacyToken() {
    return getRealUser().legacy_token
}
function getBsi20Url() {
    return conf.BSI_2_0_URL + 'default/login?token=' + getLegacyToken()
}

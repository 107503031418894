<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <nav class="vx-breadcrumb" aria-label="Breadcrumb">
        <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end text-center">
                <router-link to="/">
                    <i class="far fa-home text-primary pb-1 pt-1" role="img" aria-label="Home"/>
                </router-link>
                <span class="breadcrumb-separator mx-3 mb-1">
                    <i class="bsi-icon-forward fa-sm"/>
                </span>
            </li>
            <li v-for="(link, index) in $route.meta.breadcrumb.slice(1,-1)"
                :key="index"
                class="inline-flex items-center"
            >
                <router-link :to="link.url" v-if="link.url">{{ $t(link.title) }}</router-link>
                <span class="text-primary cursor-default" v-else>{{ $t(link.title) }}</span>
                <span class="breadcrumb-separator mx-3 flex items-start">
                    <i class="bsi-icon-forward fa-sm"/>
                </span>
            </li>
            <li class="inline-flex">
                <span v-if="$route.meta.breadcrumb.slice(-1)[0].active" class="cursor-default">
                    {{ $t($route.meta.breadcrumb.slice(-1)[0].title) }}
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'vx-breadcrumb',
}
</script>

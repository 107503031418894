const config = {
    BSI_2_0_URL:                process.env.VUE_APP_BSI_2_0_URL,
    API_URL:                    process.env.VUE_APP_API_URL,
    AUTH_HEADER:                process.env.VUE_APP_AUTH_HEADER,
    AUTH_BEARER:                process.env.VUE_APP_AUTH_BEARER,
    AUTH_USER_KEY:              process.env.VUE_APP_AUTH_USER_KEY,
    IMPERSONATE_USER_KEY:       process.env.VUE_APP_IMPERSONATE_USER_KEY,
    BANNER_TEXT:                process.env.VUE_APP_BANNER_TEXT,
    BSI_EMAIL:                  process.env.VUE_APP_BSI_EMAIL,
    BSI_ADDRESS:                process.env.VUE_APP_BSI_ADDRESS,
    BSI_FAX:                    process.env.VUE_APP_BSI_FAX,
    ORIGIN:                     process.env.VUE_APP_ORIGIN,
    RUM_SERVER_URL:             process.env.VUE_APP_RUM_URL,
    RUM_SAMPLE_RATE:            process.env.VUE_APP_TRANSACTION_SAMPLE_RATE,
    RUM_SERVICE_NAME:           process.env.VUE_APP_RUM_SERVICE_NAME,
    ENABLE_SMARTY_VALIDATION:   process.env.VUE_APP_ENABLE_SMARTY_VALIDATION,
    COLORS: {
        primary:           '#236192',
    },
    LOGIN_IMAGE:                process.env.VUE_APP_LOGIN_IMAGE,
};

export default config;

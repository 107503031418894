import config from "./../config"

const authHeaderName = config.AUTH_HEADER;
const authBearer = config.AUTH_BEARER;
const userKey = config.AUTH_USER_KEY;

export function authHeader() {
    let user = JSON.parse(localStorage.getItem(userKey));

    if (user && user.token) {
        return {[authHeaderName]: authBearer + user.token}
    } else {
        return {}
    }
}

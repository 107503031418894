/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'
import en from 'vee-validate/dist/locale/en'
import fr from 'vee-validate/dist/locale/fr'

Vue.use(VueI18n)

const i18n =  new VueI18n({
  locale: 'en', // set default locale
  messages: i18nData
})

Vue.use(VeeValidate, {
    i18n,
    dictionary: {
        en: i18nData.en,
        fr: i18nData.fr
    }
})

export default i18n;

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './Login.vue'

import BootstrapVue from "bootstrap-vue"
Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/all.css'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// Theme Configurations
import '@/../themeConfig.js'


// Globally Registered Components
import '@/globalComponents.js'


// Styles: SCSS
import '@/assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import {store} from './store'


// Vuesax Admin Filters
import '../shared/filters/filters'


// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// i18n
import i18n from '../shared/i18n/i18n'

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// Feather font icon
require('@/assets/css/iconfont.css')

// Vue cookie
const VueCookie = require('vue-cookie');
Vue.use(VueCookie)

Vue.config.productionTip = false

new Vue({
    router,
    store,
	i18n,
    render: h => h(App)
}).$mount('#app')



import Vue from 'vue'
import Router from 'vue-router'
import {ApmVuePlugin} from "@elastic/apm-rum-vue"
import config from "@/config";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL ,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/redirect',
            name: 'pageRedirect',
            component: () => import('./views/Redirect.vue')
        },
        {
            path: '/redirect_20',
            name: 'pageRedirect20',
            component: () => import('./views/Redirect20.vue')
        },
        {
            path: '/',
            name: 'pageLogin',
            component: () => import('./views/LoginPage.vue')
        },
        {
            path: '/forgot-password',
            name: 'pageForgotPassword',
            component: () => import('./views/ForgotPasswordPage.vue')
        },
        {
            path: '/reset-password',
            name: 'pageResetPassword',
            component: () => import('./views/ResetPasswordPage.vue')
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            component: () => import('../shared/views/pages/Error404.vue')
        },
    ]
})

if (config.RUM_SERVER_URL) {
    Vue.use(ApmVuePlugin, {
        router,
        config: {
            serviceName: config.RUM_SERVICE_NAME,
            serverUrl: config.RUM_SERVER_URL,
            transactionSampleRate: parseFloat(config.RUM_SAMPLE_RATE)
        }
    })
}

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
});


export default router

import {loginService} from '../services'
import {localstorage, utility} from '../../_helpers'
import router from '../router'

const user = localstorage.getUser()

const initialState = user
				?
					{status: {loggedIn: true}, user}
				:
					{status: {}, user}

export const authentication = {

    namespaced: true,
    state: {
    	...initialState,
		preferences: {
			language: 'en',
			tour: {}
		}
	},

    actions: {
        async forgotPassword({dispatch, commit}, username) {
            await loginService.forgotPassword(username)
        },
        async handleRedirect({state}, {redirect_url, type, uid}) {
            if (utility.userAuthenticated()) {
                let auth_redirect = utility.getUserRedirectUrl()
                if (type && uid) {
                    auth_redirect += '/' + type + '/' + uid
                }
                location.replace(
                   auth_redirect.startsWith('http') ?
                       auth_redirect :
                       '/' + auth_redirect)
            } else {
                await router.push({
                    path: '/',
                    query: {
                        redirect: redirect_url,
                    }
                })
            }
        },
        async resetPassword({dispatch, commit}, username) {
            await loginService.resetPassword(username)
        },
		async getPreferences({commit}) {
			let preferences = await loginService.getPreferences()
            commit('getPreferencesSuccess', preferences)
		},
        async updatePreferences({dispatch, state}, preferences) {
			await loginService.updatePreferences(preferences)
		},
        async login({dispatch, commit, state}, {username, password, locale, redirect_url = null}) {
            commit('loginRequest', {username})
            try {
                let user = await loginService.login(username, password)
                commit('loginSuccess', user)

                if (!user.redirect_url) {
                    location.replace("/app/not-found")
                    return
                }

                if (user.redirect_url.startsWith('http')) {
                    location.replace(user.redirect_url)
                    return
                }

                if (redirect_url === null) {
                    location.replace('/' + user.redirect_url)
                    return
                }

                if (redirect_url.startsWith('http')) {
                    location.replace(redirect_url)
                    return
                }

                location.replace('/' + user.redirect_url + redirect_url)
            } catch(error) {
                commit('loginFailure', error)
                dispatch('alert/error', error, {root: true})
            }
        },
        logout({commit}) {
            loginService.logout()
            commit('logout')
        }
    },

    mutations: {
		getPreferencesSuccess(state, preferences) {
			state.preferences = preferences
		},
        loginRequest(state, user) {
            state.status = {loggingIn: true}
            state.user = user
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true}
            state.user = user
        },
        loginFailure(state) {
            state.status = {}
            state.user = null
        },
        logout(state) {
            state.status = {}
            state.user = null
        }
    },
}

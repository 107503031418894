<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        computed: {
            alert() {
                return this.$store.state.alert;
            }
        },
        watch: {
            $route() {
                //clear alerts on page location change
                this.$store.dispatch('alert/clear');
            }
        },
    };

</script>

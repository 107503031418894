import config from "../config";

export const localstorage = {
    getUser,
    getImpersonatedUser,
    getImpersonatedUsername,
    getImpersonatedUserUrl,
};

function getUser() {
    return JSON.parse(localStorage.getItem(config.AUTH_USER_KEY));
}
function getImpersonatedUser() {
    return JSON.parse(localStorage.getItem(config.IMPERSONATE_USER_KEY));
}
function getImpersonatedUsername() {
    let u = getImpersonatedUser()
    return (u) ? u.username : null
}
function getImpersonatedUserUrl() {
    let u = getImpersonatedUser()
    return (u) ? u.url : null
}
